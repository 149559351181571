import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Markdown from "../Markdown";

const markdownContent = `
<!-- markdownlint-capture -->
<!-- markdownlint-disable -->

Last modified: October 1st, 2023.

<!-- markdownlint-restore -->

Welcome to CAPSTONE Gym! At CAPSTONE Gym, we are committed to protecting your privacy and safeguarding your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your personal data when you visit our website or use our services. By using our website and services, you consent to the practices described in this policy.

Please read this policy carefully to understand how we handle and treat your personal information.

## What information do we collect?

We may collect and process the following personal information from you:

- **Personal Information:** This includes your name, email address, phone number, and other contact details you provide when you sign up for our services, request information, or contact us.

- **Payment Information:** When you make payments for our services, we collect payment card information and billing details. Please note that we use secure payment processing services to handle payments, and we do not store your payment card information.
- **Location Information:** We may collect your location information if you choose to share it with us for specific services such as finding nearby gym locations.

## What do we use your information for?

We may use your information for the following purposes:

- To provide and improve our services, including personalized content and recommendations.

- To communicate with you, respond to your inquiries, and send updates and promotional materials.

- To process payments and manage your account.

- To monitor and analyze website usage and trends.

## How do we protect your information?

We do not sell, trade, or otherwise transfer your personal information to third parties without your consent. However, we may share your information with:

Service Providers: We may engage third-party service providers to assist us in providing our services. These providers may have access to your personal information only to perform specific tasks on our behalf.

Legal Requirements: We may disclose your information to comply with legal obligations, protect our rights, or respond to legal requests from law enforcement or government authorities.

## Your Choices

You have the following choices regarding your personal information:

You can update your account information and communication preferences through your account settings.

You can opt-out of receiving promotional emails by following the instructions provided in the email.


## Changes to our Privacy Policy

If we decide to change our privacy policy, we will post those changes on this page, and/or update the Privacy Policy modification date below.

`;

export const Privacy = () => {
  return (
    <Container>
      <Box sx={{ mt: 7, mb: 12 }}>
        <Typography variant="h3" gutterBottom marked="center" align="center">
          Privacy Policy
        </Typography>
        <Markdown>{markdownContent}</Markdown>
      </Box>
    </Container>
  );
};
