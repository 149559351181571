// src/components/AppNavBar.js
import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItemText,
  useMediaQuery,
  ListItemButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import { setFocusOnInputField } from "../../slices/focusInputSlice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
const AppNavBar = () => {
  const navItems = ["Events", "Membership", "AboutUs", "Contact"];
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobileOrTablet = useMediaQuery("(max-width: 1024px)");
  const [hideHeader, setHideHeader] = useState(false);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setHideHeader(true);
    } else {
      setHideHeader(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const scrollToEnquiry = () => {
    const element = document.getElementById("enquiry");
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
      dispatch(setFocusOnInputField("name"));
    }
  };

  return (
    <AppBar
      position="sticky"
      style={{
        transform: hideHeader ? "translateY(-40px)" : "none",
        transition: "transform 0.3s",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#333",
          color: "#fff",
          top: "0",
          left: "0",
          right: "0",
          zIndex: "1000",
          height: "40px",
          transform: hideHeader ? "translateY(-100%)" : "none",
          transition: "transform 0.3s",
        }}
      >
        <Typography>Contact: 9637666990 | Email: info@capstone.com</Typography>
      </div>
      <Toolbar>
        <Link to="/">
          <Box
            component="img"
            sx={{ height: 54 }}
            alt="Logo"
            src="capstone192.png"
          />
        </Link>
        {isMobileOrTablet && (
          <IconButton
            sx={{ marginLeft: "auto" }}
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
        )}
        {!isMobileOrTablet && (
          <List
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "20px",
              marginLeft: "auto",
            }}
          >
            <div>
              <ListItemButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <ListItemText primary="Programme" />
                <ListItemIcon sx={{ minWidth: "24px" }}>
                  <ArrowDropDownIcon />
                </ListItemIcon>
              </ListItemButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  component={Link}
                  to={"/personaltrainingprogramme"}
                  onClick={handleClose}
                >
                  Personal Training Programme
                </MenuItem>
                <MenuItem
                  component={Link}
                  to={"/selftrainingprogramme"}
                  onClick={handleClose}
                >
                  Self-Trained Individuals
                </MenuItem>
              </Menu>
            </div>
            {navItems.map((item, index) => (
              <ListItemButton
                key={index}
                component={Link}
                to={`/${item.toLowerCase()}`}
              >
                <ListItemText primary={item} />
              </ListItemButton>
            ))}

            <ListItemButton
              component={Link}
              onClick={scrollToEnquiry}
              to={"/enquiry"}
            >
              <ListItemText primary="Enquiry" />
            </ListItemButton>
            <ListItemButton component={Link} to="/login">
              <PersonIcon />
              <ListItemText primary="Login" />
            </ListItemButton>
          </List>
        )}
      </Toolbar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <List>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="programme-menu-content"
              id="programme-menu-header"
            >
              <Typography>Programme</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItemButton
                  component={Link}
                  to={"/personaltrainingprogramme"}
                  onClick={toggleDrawer} // Add this to close the drawer
                >
                  <ListItemText primary="Personal Training Programme" />
                </ListItemButton>
                <ListItemButton
                  component={Link}
                  to={"/selftrainingprogramme"}
                  onClick={toggleDrawer} // Add this to close the drawer
                >
                  <ListItemText primary="Self-Trained Individuals" />
                </ListItemButton>
              </List>
            </AccordionDetails>
          </Accordion>
          {navItems.map((item, index) => (
            <ListItemButton
              button
              key={index}
              component={Link}
              to={`/${item.toLowerCase()}`}
              onClick={toggleDrawer} // Add this to close the drawer
            >
              <ListItemText primary={item} />
            </ListItemButton>
          ))}
          <ListItemButton
            component={Link}
            to={"/enquiry"}
            onClick={() => {
              scrollToEnquiry();
              toggleDrawer();
            }}
          >
            <ListItemText primary="Enquiry" />
          </ListItemButton>
          <ListItemButton
            button
            component={Link}
            to="/login"
            onClick={toggleDrawer}
          >
            <PersonIcon />
            <ListItemText primary="Login" />
          </ListItemButton>
        </List>
      </Drawer>
    </AppBar>
  );
};

export default AppNavBar;
