import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Typography } from "@mui/material";
import "./customCarousel.css";
const items = [
  {
    image: "customCarousel/img1.jpg",
    title: "Image 1",
    description: "Description for Image 1",
  },
  {
    image: "customCarousel/img2.jpg",
    title: "Image 2",
    description: "Description for Image 2",
  },
  {
    image: "customCarousel/img3.jpg",
    title: "Image 3",
    description: "Description for Image 3",
  },
];

const Item = ({ item }) => {
  return (
    <Paper className="carousel-item">
      <div className="item-content">
        <Typography variant="h1">{item.title}</Typography>
        <Typography variant="body1">{item.description}</Typography>
      </div>
      <img src={item.image} alt={item.title} />

      {/* Add custom content */}
    </Paper>
  );
};

const CustomCarousel = () => {
  return (
    <div className="custom-carousel">
      <Carousel
        autoPlay={true}
        swipe={false}
        animation="slide"
        cycleNavigation={true}
        navButtonsAlwaysVisible={true}
        indicatorContainerProps={{
          style: {
            position: "absolute",
            bottom: "100px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
          },
        }}
      >
        {items.map((item, index) => (
          <Item key={index} item={item} />
        ))}
      </Carousel>
    </div>
  );
};

export default CustomCarousel;
