import axios from "axios";

//const BASE_URL = "http://localhost:3400";
const BASE_URL = "https://api.capstonegym.com";

export const sendEnquiry = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/enquiries`, formData);
    return response;
  } catch (error) {
    throw error;
  }
};

export const sentupdateEnquiry = async (formData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/enquiries/updateEnquiry`,
      formData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllEnquiry = async (url, config) => {
  try {
    const response = await axios.get(`${BASE_URL}${url}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendMember = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/members`, formData);
    return response;
  } catch (error) {
    throw error;
  }
};

export const sentupdateMember = async (formData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/members/updateMember`,
      formData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getMembersMembershipData = async (url, config) => {
  try {
    const response = await axios.get(`${BASE_URL}${url}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addMembersMembership = async (formData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/members/addMembersMembership`,
      formData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const SendEmail = async (emailDetails) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/members/sendMail`,
      emailDetails
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const ThankyouEmail = async (emailDetails) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/members/thankyouMail`,
      emailDetails
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateMembersMembership = async (formData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/members/updateMemberMembership`,
      formData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllMembers = async (url, config) => {
  try {
    const response = await axios.get(`${BASE_URL}${url}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getinvoice = async (url, config) => {
  try {
    const response = await axios.get(`${BASE_URL}${url}`, config);
    console.log("Fetched Invoice Data:", response.data);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMonthlyCollection = async (headers) => {
  try {
    const response = await axios.get(`${BASE_URL}/members/MonthlyCollection`, {
      headers,
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching monthly collection:", error);
    throw error;
  }
};

export const getMemberMembership = async (memberId) => {
  try {
    const response = await axios.get(`${BASE_URL}/members/memberMembership`, {
      params: { memberId: memberId },
    });
    return response;
  } catch (error) {
    console.error(
      "Error fetching membership details:",
      error.response?.data || error.message
    );
    throw error;
  }
};

export const checkMembershipExists = async ({ ...membershipDetails }) => {
  try {
    const response = await axios.post(
      `/members/CheckMemberMembership/`,
      membershipDetails
    );
    return response.data.exists;
  } catch (error) {
    console.error(
      "Error fetching membership details:",
      error.response?.data || error.message
    );
    throw error;
  }
};

// questions
export const getAllQuestions = async (config) => {
  try {
    const response = await axios.get(`${BASE_URL}/members/questions`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendQuestionAns = async (formData, memId) => {
  try {
    const response = await axios.post(`${BASE_URL}/members/questions`, {
      formData,
      memId,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAnswers = async (config) => {
  try {
    const response = await axios.get(`${BASE_URL}/members/answer`, config);
    return response;
  } catch (error) {
    throw error;
  }
};
// trainer
export const sendTrainer = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/trainers`, formData);
    return response;
  } catch (error) {
    throw error;
  }
};

export const sentupdateTrainer = async (formData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trainers/updateTrainer`,
      formData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllTrainers = async (url, config) => {
  try {
    const response = await axios.get(`${BASE_URL}${url}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllMemberships = async (url, config) => {
  try {
    const response = await axios.get(`${BASE_URL}${url}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addUpdateMembershipService = async (url, postData) => {
  try {
    const response = await axios.post(`${BASE_URL}${url}`, postData);
    return response;
  } catch (error) {
    throw error;
  }
};

export const login = async (loginData) => {
  try {
    const response = await axios.post(`${BASE_URL}/login`, loginData);
    return response.data;
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const register = async (registerData) => {
  try {
    const response = await axios.post(`${BASE_URL}/register`, registerData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
