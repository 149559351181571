import React from "react";
import { useState, useEffect } from "react";
import { getAllEnquiry } from "../../../../axiosApi/api";
import { Grid, Typography, Button, ButtonGroup } from "@mui/material";
import MainCard from "../cards/MainCard";
import AnalyticCard from "../cards/AnalyticCard";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectEnquiryData,
  selectEnquiryStatus,
  setEnquiryData,
  fetchEnquiryData,
} from "../../../../slices/enquirySlice";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs from "dayjs";

const Enquiries = () => {
  const dispatch = useDispatch();
  const enquiryData = useSelector(selectEnquiryData);
  const enquiryStatus = useSelector(selectEnquiryStatus);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (enquiryStatus === "idle") {
      dispatch(fetchEnquiryData());
    } else {
      dispatch(setEnquiryData(enquiryData));
    }
  }, [dispatch, enquiryStatus, enquiryData]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: isSmallScreen ? 2 : 1,
      minWidth: 100,
    },
    {
      field: "name",
      headerName: "Name",
      flex: isSmallScreen ? 2 : 1,
      minWidth: 150,
    },
    {
      field: "email_id",
      headerName: "Email",
      flex: isSmallScreen ? 2 : 1,
      minWidth: 180,
    },
    {
      field: "contact_no",
      headerName: "Contact No.",
      flex: isSmallScreen ? 2 : 1,
      minWidth: 150,
    },
    {
      field: "interest",
      headerName: "Interest",
      flex: isSmallScreen ? 2 : 1,
      minWidth: 150,
    },
    {
      field: "enquiry_date",
      headerName: "Enquiry Date",
      flex: isSmallScreen ? 2 : 1,
      minWidth: 150,
      renderCell: (params) =>
        dayjs(params.row.enquiry_date).format("DD/MM/YYYY"),
    },
  ];

  const navigate = useNavigate();
  const handleRowClick = (params, event, details) => {
    const selectedEnquiryId = params.row.id;
    navigate(`/adminapp/enquiryDetails/${selectedEnquiryId}`);
  };

  const addNewEnquiry = () => {
    navigate(`/adminapp/enquiryDetails/`);
  };
  return (
    <>
      <Grid
        container
        rowSpacing={4.5}
        columnSpacing={2.75}
        sx={{ background: "#fafafb" }}
      >
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: -2.25 }}
        >
          <Typography variant="h5">Enquiries</Typography>
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
          >
            <Button
              variant="contained"
              startIcon={<LibraryAddIcon />}
              onClick={() => addNewEnquiry()}
            >
              New Enquiry
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticCard
            title="Total Enquiries"
            count={enquiryData.length}
            extra="5"
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <MainCard sx={{ mt: 2 }} content={false}>
            <Box sx={{ width: "100%", height: 400 }}>
              <DataGrid
                rows={enquiryData}
                columns={columns}
                pageSize={50}
                onRowClick={handleRowClick}
                initialState={{
                  sorting: {
                    sortModel: [{ field: "id", sort: "desc" }],
                  },
                }}
              />
            </Box>
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default Enquiries;
