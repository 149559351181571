import React, { useRef } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import "./galleryCarousel.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { styled } from "@mui/system";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const items = [
  {
    image: "gallery/image01.jpg",
    title: "Image 1",
    description: "Description for Image 1",
  },
  {
    image: "gallery/image02.jpg",
    title: "Image 2",
    description: "Description for Image 2",
  },
  {
    image: "gallery/image03.jpg",
    title: "Image 3",
    description: "Description for Image 3",
  },
  {
    image: "gallery/image04.jpg",
    title: "Image 4",
    description: "Description for Image 4",
  },
  {
    image: "gallery/image05.jpg",
    title: "Image 5",
    description: "Description for Image 5",
  },
  {
    image: "gallery/image06.jpg",
    title: "Image 6",
    description: "Description for Image 6",
  },
];

const Root = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const NextPrevButtons = ({ next, previous }) => {
  return (
    <div className="next-prev-buttons">
      <IconButton size="large" onClick={previous} className="_left">
        <ArrowBackIosIcon />
      </IconButton>
      <IconButton size="large" onClick={next} className="_right">
        <ArrowForwardIosIcon />
      </IconButton>
    </div>
  );
};

const Item = ({ item }) => {
  return (
    <Card sx={{ maxWidth: 345, margin: "auto" }}>
      <CardActionArea>
        <CardMedia component="img" height="300" image={item.image} alt={item.title} />
        <Root>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {item.description}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {item.title}
            </Typography>
          </CardContent>
        </Root>
      </CardActionArea>
    </Card>
  );
};
const GalleryCarousel = () => {
  const carouselRef = useRef(null);
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 3,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      partialVisibilityGutter: 30,
    },
  };
  return (
    <div
      style={{
        position: "relative",
        backgroundImage: `url('/gallery/bannerBg.jpg')`,
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        minHeight: "320px",
        flexDirection: "column",
        justifyContent: "space-around",
        padding: "50px 20px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={1}></Grid>
        <Grid item xs={12} md={10}>
          <Box sx={{ width: "100%", textAlign: "center" }} py={2}>
            <Typography variant="h3" sx={{ mb: 8 }}>
              Capstone Fitness Center
            </Typography>
            <Carousel
              ref={(el) => (carouselRef.current = el)}
              additionalTransfrom={0}
              autoPlaySpeed={3000}
              centerMode={false}
              containerClass="container"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              showDots={true}
              responsive={responsive}
              rewind={true}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              sliderClass=""
              slidesToSlide
              swipeable
              arrows={false}
            >
              {items.map((item, index) => (
                <Item key={index} item={item} sx={{ margin: "auto" }} />
              ))}
            </Carousel>
          </Box>
        </Grid>
        <Grid item md={1}></Grid>
      </Grid>
      <NextPrevButtons
        next={() => carouselRef.current.next()}
        previous={() => carouselRef.current.previous()}
      />
    </div>
  );
};

export default GalleryCarousel;
