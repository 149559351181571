import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllTrainers,
  sendTrainer,
  sentupdateTrainer,
} from "../axiosApi/api";

const initialState = {
  trainersData: [],
  status: "idle",
  error: null,
  selectedTrainer: {},
  sendTrainerDataResponse: "",
  flagReset: null,
};

export const fetchTrainersData = createAsyncThunk(
  "trainers/fetchTrainersData",
  async (unusedPayload, { dispatch }) => {
    try {
      dispatch(setTrainerStatus("loading"));
      const accessToken = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await getAllTrainers("/trainers", { headers });
      dispatch(setTrainerStatus("succeeded"));
      return response;
    } catch (error) {
      console.error("Error fetching trainers data:", error);
      dispatch(setTrainerStatus("failed"));
      throw error;
    }
  }
);

export const sendTrainersData = createAsyncThunk(
  "trainers/sendTrainersData",
  async (updatedData) => {
    try {
      if (typeof updatedData.id === "undefined") {
        const response = await sendTrainer(updatedData);
        return response.status;
      } else {
        const response = await sentupdateTrainer(updatedData);
        return response.status;
      }
    } catch (error) {
      throw new Error(
        error.response.data.message || "Failed to send data to the database."
      );
    }
  }
);

export const trainersSlice = createSlice({
  name: "trainers",
  initialState,
  reducers: {
    setTrainersData: (state, action) => {
      state.trainersData = action.payload;
    },
    setTrainerStatus: (state, action) => {
      state.status = action.payload;
    },
    addTrainer: (state, action) => {
      state.trainersData.push(action.payload);
    },

    updateTrainer: (state, action) => {
      const updatedTrainer = action.payload;
      const index = state.trainersData.findIndex(
        (trainer) => trainer.id === updatedTrainer.id
      );
      if (index !== -1) {
        state.trainersData[index] = updatedTrainer;
      }
    },

    flagReset: (state, action) => {
      state.sendTrainerDataResponse = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrainersData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTrainersData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.trainersData = action.payload;
      })
      .addCase(fetchTrainersData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(sendTrainersData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendTrainersData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.sendTrainerDataResponse = action.payload;
      })
      .addCase(sendTrainersData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  setTrainersData,
  addTrainer,
  setTrainerStatus,
  updateTrainer,
  flagReset,
} = trainersSlice.actions;
export const selectTrainersData = (state) => state.trainers.trainersData;
export const selectTrainerStatus = (state) => state.trainers.status;
export const selectTrainerById = (state, trainerId) => {
  return state.trainers.trainersData.find(
    (trainer) => trainer.id === Number(trainerId)
  );
};
export const sendTrainerDataRes = (state) => {
  return state.trainers.sendTrainerDataResponse;
};

export default trainersSlice.reducer;
