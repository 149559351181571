import { configureStore } from "@reduxjs/toolkit";
import enquirySlice from "../slices/enquirySlice";
import focusInputSlice from "../slices/focusInputSlice";
import menu from "../slices/menu";
import membersReducer from "../slices/membersSlice";
import trainersSlice from "../slices/trainersSlice";
import membershipsSlice from "../slices/membershipsSlice";
export const store = configureStore({
  reducer: {
    enquiry: enquirySlice,
    focus: focusInputSlice,
    menu: menu,
    members: membersReducer,
    trainers: trainersSlice,
    memberships: membershipsSlice,
  },
});
