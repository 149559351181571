import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectEnquiryById,
  fetchEnquiryData,
  selectEnquiryData,
  sendEnquiryData,
  sendEnquiryDataRes,
  flagReset,
} from "../../../../slices/enquirySlice";

import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  Divider,
  FormControlLabel,
  TextField,
  Checkbox,
  Paper,
  ButtonGroup,
  TableContainer,
  Table,
  TableBody,
  Snackbar,
  Alert,
} from "@mui/material";
import CustomDialog from "../../../common/CustomDialog";
import CancelIcon from "@mui/icons-material/Cancel";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ResponsiveTableRow from "../../../common/ResponsiveTableRow";
import styled from "@emotion/styled";
import TabContext from "@mui/lab/TabContext";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import SaveIcon from "@mui/icons-material/Save";
import {
  interestOptions,
  sourceofenquiryOptions,
  enquirytypeOptions,
} from "../../../quickEnquiry/constant";
import { width } from "@mui/system";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "unset",
  },
  "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "0px",
  },
}));
const ViewEnquiryDetails = (props) => {
  // snackbar work start

  const [errors, setErrors] = useState({
    name: "",
    email_id: "",
    contact_no: "",
    interest: "",
    comment: "",
    enquiry_date: "",
    enquirytype: "",
    sourceofenquiry: "",
  });
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const [notification, setNotification] = useState({});
  const [identifyDialog, setIdentifyDialog] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const selectedEnquiry = useSelector((state) => selectEnquiryById(state, id));
  const [sendMember, setSendMember] = useState();
  const enquiryData = useSelector(selectEnquiryData);
  const [newErrors, setNewErrors] = useState({});
  const [tabsHeadValue, setTabsValueHead] = useState("1");
  const [editableFields, setEditableFields] = useState(false);
  const [editcud, setEditCud] = useState({
    save: false,
    cancel: false,
    update: true,
  });
  let sendenquiryres = useSelector(sendEnquiryDataRes);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [followupState, setFollowupState] = useState({
    followup1Disabled: false,
    followup2Disabled: true,
    followups1: false,
    followups2: true,
  });

  const [enquiry, setEnquiry] = useState({
    name: "",
    interest: "",
    contact_no: "",
    email_id: "",
    comment: "",
    enquiry_date: "",
    enquirytype: "",
    sourceofenquiry: "",
    followup1_details: "",
    followup2_details: "",
    followup1: false,
    followup2: false,
  });
  useEffect(() => {
    dispatch(fetchEnquiryData());
  }, [id]);

  useEffect(() => {
    if (sendenquiryres === 200) {
      setOpen(true);
      setNotification({
        variant: "success",
        message: "Enquiry Submitted Successfully! .",
      });
      dispatch(flagReset("0"));
      dispatch(fetchEnquiryData());
      if (enquiry.id === undefined) {
        navigate("/adminapp/enquiries");
      }
    } else if (sendenquiryres === 208) {
      setOpen(true);
      setNotification({
        variant: "error",
        message: "Enquiry Already Existed.",
      });
      setEditableFields(true);
      setEditCud({ update: false, save: true });
    }
  }, [sendenquiryres]);

  const handleCheckboxChange = (field, value) => {
    setEnquiry((prev) => {
      const updatedState = {
        ...prev,
        [field]: value,
      };

      if (field === "followup1" && !value) {
        updatedState.followup2 = false;
      }

      return updatedState;
    });
  };

  const handleFollowupDetailsChange = (field, value) => {
    setEnquiry((prev) => {
      const updatedState = {
        ...prev,
        [field]: value,
      };

      return updatedState;
    });
  };

  useEffect(() => {
    if (props.mode === "add") {
      setEditableFields(true);
      setEditCud({
        save: true,
        cancel: true,
        update: false,
      });
      setEnquiry({
        name: "",
        interest: "",
        contact_no: "",
        email_id: "",
        comment: "",
        enquiry_date: "",
        enquirytype: "",
        sourceofenquiry: "",
        followup1: "",
        followup1_details: "",
        followup2: "",
        followup2_details: "",
      });
    } else {
      setEnquiry(
        enquiryData.find((enquiry) => parseInt(enquiry.id) === parseInt(id))
      );
    }
  }, []);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleConfirmForCancel = () => {
    if (props.mode === "add") {
      setEnquiry({
        name: "",
        interest: "",
        contact_no: "",
        email_id: "",
        comment: "",
        enquiry_date: "",
        enquirytype: "",
        sourceofenquiry: "",
        followup1_details: "",
        followup2_details: "",
        followup1: "",
        followup2: "",
      });
      setEditableFields(true);
      setEditCud({
        save: true,
        cancel: true,
        update: false,
      });
      setErrors({});
    } else {
      setEnquiry(selectedEnquiry);
      setEditableFields(false);
      setEditCud({
        save: false,
        cancel: false,
        update: true,
      });
    }

    setDialogOpen(false);
  };

  const handleConfirmForBacktoEnquiry = () => {
    setEnquiry(selectedEnquiry);
    navigate("/adminapp/enquiries");
  };

  const handleChange = (event, newValue) => {
    setTabsValueHead(newValue);
  };

  const CustomTab = styled(Tab)(({ theme }) => ({
    position: "relative",
    overflow: "visible",
    "&.Mui-selected": {
      overflow: "visible",
      backgroundColor: "#242105",
      color: "#ffeb3b",
      "&::before": {
        content: '""',
        position: "absolute",
        top: "90%",
        left: "50%",
        transform: "translateX(-50%)",
        width: 0,
        height: 0,
        borderTop: `8px solid ${theme.palette.primary.main}`,
        borderLeft: "8px solid transparent",
        borderRight: "8px solid transparent",
        marginTop: -4,
        zIndex: 1,
        overflow: "visible",
      },
    },
  }));

  const commonStyleForInput = {
    label: {
      shrink: true,
      style: { fontWeight: "bold", textTransform: "uppercase", color: "" },
    },
    input: {
      style: { color: "#242105" },
    },
  };
  const handleUpdateClick = () => {
    setEditableFields(true);
    setEditCud({ save: true, cancel: true, update: false });

    if (enquiry.followup1 === true && enquiry.followup1_details === "") {
      setFollowupState({
        followup1Disabled: false, // Enable followup1
        followups2: true,
        followup2Disabled: true, // Ensure followup2 remains disabled
      });
    } else if (enquiry.followup1_details?.trim()) {
      setFollowupState({
        followup1Disabled: true, // Disable followup1 if details are present
        followups1: true,
      });
    }

    if (enquiry.followup2 === true && enquiry.followup2_details === "") {
      setFollowupState({
        followup2Disabled: false, // Ensure followup2 remains disabled
        followups1: true,
        followup1Disabled: true,
      });
    } else if (enquiry.followup2_details) {
      setFollowupState({
        followup2Disabled: true, // Disable followup2 if details are present
        followup1Disabled: true,
        followups1: true,
        followups2: true,
      });
    }
    if (enquiry) {
      setEnquiry({
        ...enquiry,
        followup1: enquiry.followup1 || false,
        followup1_details:
          enquiry.followup1_details && enquiry.followup1_details !== ""
            ? enquiry.followup1_details
            : "",
        followup2: enquiry.followup2 || false,
        followup2_details:
          enquiry.followup2_details && enquiry.followup2_details !== ""
            ? enquiry.followup2_details
            : "",
      });
    }
  };

  const handleBacktoEnquiry = () => {
    setIdentifyDialog(false);
    setDialogOpen(true);
  };

  const handleInputChange = (field, value) => {
    if (!editableFields) return; // Prevent update if fields are not editable

    if (errors[field]) {
      const removeError = Object.fromEntries(
        Object.entries(errors).filter(([k]) => k !== field)
      );
      console.log("after removed Error", removeError);
      setErrors(removeError);
    }

    if (field === "contact_no") {
      const numericValue = value.replace(/[^0-9]/g, "");
      if (numericValue.length <= 10) {
        const updatedEnquiry = { ...enquiry, [field]: numericValue };
        setEnquiry(updatedEnquiry);
      }
    } else if (field === "followup1_details" || field === "followup2_details") {
      handleFollowupDetailsChange(field, value);
    } else {
      const updatedEnquiry = { ...enquiry, [field]: value };
      setEnquiry(updatedEnquiry);
    }
  };

  const handleCancelClick = () => {
    setDialogOpen(true);
    setIdentifyDialog(true);
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    // validation
    const newErrors = {};
    const phoneRegex = /^[0-9]{10}$/;
    if (!enquiry?.name) newErrors.name = "Name is required";
    if (!enquiry?.email_id) newErrors.email_id = "Email is required";
    if (!phoneRegex.test(enquiry?.contact_no))
      newErrors.contact_no = "Please enter a valid 10-digit phone number";
    if (!enquiry?.interest) newErrors.interest = "Select an Interest";
    if (!enquiry?.enquiry_date) newErrors.enquiry_date = "Selecta a Date";
    if (!enquiry?.enquirytype) newErrors.enquirytype = " select a enquiry type";
    if (!enquiry?.sourceofenquiry)
      newErrors.sourceofenquiry = "select a source of enquiry";
    // if (!enquiry?.followup1) newErrors.followup1 = "select a followup 1 ";
    // if (!enquiry?.followup2) newErrors.followup2 = "select a followup 2";
    // if (!enquiry?.followup1_details)
    //   newErrors.followup1_details = "select a followup details 1";
    // if (!enquiry?.followup2_details)
    //   newErrors.followup2_details = "select a followup details 2";
    if (enquiry) {
      setEnquiry({
        ...enquiry,
        followup1: enquiry.followup1,
        followup1_details:
          enquiry.followup1_details && enquiry.followup1_details !== ""
            ? enquiry.followup1_details
            : "",
        followup2: enquiry.followup2,
        followup2_details:
          enquiry.followup2_details && enquiry.followup2_details !== ""
            ? enquiry.followup2_details
            : "",
      });
    }
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        dispatch(sendEnquiryData(enquiry));
        const updatedEnquiry = {
          ...enquiry,
          followup1_disabled:
            enquiry.followup1 && enquiry.followup1_details ? true : false,
          followup2_disabled:
            enquiry.followup2 && enquiry.followup2_details ? true : false,
        };

        // Update the enquiry state to reflect the disabled fields
        setEnquiry(updatedEnquiry);
        setEditableFields(false);
        setEditCud({ save: false, cancel: false, update: true });
      } catch (error) {
        console.error("Error view enquiry:", error.message);
      }
    }
  };

  const handleConvetToMember = () => {
    const Member = {
      name: enquiry?.name,
      email_id: enquiry?.email_id,
      contact_no: enquiry?.contact_no,
    };
    console.log("sending enquiry data to member ", Member);
    navigate("/adminapp/memberDetails", { state: { data: Member } });
  };

  return (
    <form>
      <Grid
        container
        rowSpacing={4.5}
        columnSpacing={2.75}
        sx={{ background: "#fafafb" }}
      >
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">Enquiry Details</Typography>
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
          ></ButtonGroup>
        </Grid>

        <Grid item sx={{ margin: "auto" }} xs={12} md={8} lg={9}>
          <Paper>
            <TabContext value={tabsHeadValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{ overflow: "visible" }}
                >
                  <CustomTab label="Enquiry Details" value="1" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Grid
                  container
                  spacing={2}
                  item
                  xs={12}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack
                      direction={{ xs: "column", sm: "row", md: "row" }}
                      spacing={2}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        disabled={!editcud.update}
                        startIcon={<EditIcon />}
                        onClick={handleUpdateClick}
                      >
                        Update Enquiry Details
                      </Button>
                      {editcud.cancel ? (
                        <Button
                          variant="contained"
                          size="small"
                          startIcon={<CancelIcon />}
                          onClick={handleCancelClick}
                        >
                          {props.mode === "add" ? "Clear" : "Cancel"}
                        </Button>
                      ) : (
                        ""
                      )}
                      {isSmallScreen ? (
                        ""
                      ) : (
                        <Button
                          variant="contained"
                          size="small"
                          startIcon={<SaveIcon />}
                          disabled={!editcud.save}
                          onClick={handleSaveClick}
                        >
                          Save Enquiry
                        </Button>
                      )}
                    </Stack>
                    <Stack direction="row" sx={{ mt: { xs: "1rem", sm: "0" } }}>
                      <Button
                        variant="contained"
                        size="small"
                        startIcon={<ContactMailIcon />}
                        onClick={() =>
                          editcud.save === true
                            ? handleBacktoEnquiry()
                            : navigate("/adminapp/enquiries")
                        }
                      >
                        Back to Enquiries
                      </Button>
                    </Stack>
                    {identifyDialog ? (
                      <CustomDialog
                        open={dialogOpen}
                        handleClose={handleDialogClose}
                        onConfirm={handleConfirmForCancel}
                        content={"Do you want to Discard Changes ?"}
                      />
                    ) : (
                      <CustomDialog
                        open={dialogOpen}
                        handleClose={handleDialogClose}
                        onConfirm={handleConfirmForBacktoEnquiry}
                        content={
                          "Some of the details are unsaved. Do you want to go back without saving ?"
                        }
                      />
                    )}
                    <Snackbar
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity={notification.variant}
                        variant="filled"
                        sx={{ width: "100%" }}
                      >
                        {notification.message}
                      </Alert>
                    </Snackbar>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 3 }} />

                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      <ResponsiveTableRow
                        errorCellOne={errors.name}
                        errorCellTwo={errors.email_id}
                        memberFieldCellOne={enquiry?.name}
                        memberFieldCellTwo={enquiry?.email_id}
                        memberFieldCellOneType="text"
                        memberFieldCellTwoType="text"
                        isSmallScreen={isSmallScreen}
                        // editableFields={editableFields}
                        fieldOneEditable={editableFields}
                        fieldTwoEditable={editableFields}
                        cellOneName="Name"
                        cellTwoName="Email Id"
                        fieldOneName="name"
                        fieldTwoName="email_id"
                        handleInputChange={handleInputChange}
                        errorObject={newErrors}
                      />
                      <ResponsiveTableRow
                        errorCellOne={errors.contact_no}
                        errorCellTwo={errors.interest}
                        memberFieldCellOne={enquiry?.contact_no}
                        memberFieldCellTwo={enquiry?.interest}
                        memberFieldCellTwoValue={enquiry?.interest}
                        memberFieldCellTwoOptions={interestOptions}
                        memberFieldCellOneType="text"
                        memberFieldCellTwoType="dropdown"
                        isSmallScreen={isSmallScreen}
                        // editableFields={editableFields}
                        fieldOneEditable={editableFields}
                        fieldTwoEditable={editableFields}
                        cellOneName="Contact No"
                        cellTwoName="Interest"
                        fieldOneName="contact_no"
                        fieldTwoName="interest"
                        handleInputChange={handleInputChange}
                        errorObject={newErrors}
                      />
                      <ResponsiveTableRow
                        errorCellOne={errors.enquiry_date}
                        memberFieldCellOne={enquiry?.enquiry_date}
                        memberFieldCellTwo={enquiry?.comment}
                        memberFieldCellOneType="date"
                        memberFieldCellTwoType="text"
                        isSmallScreen={isSmallScreen}
                        fieldOneEditable={editableFields}
                        fieldTwoEditable={editableFields}
                        cellOneName="Enquiry Date"
                        cellTwoName="Comment"
                        fieldOneName="enquiry_date"
                        fieldTwoName="comment"
                        handleInputChange={handleInputChange}
                        errorObject={newErrors}
                      />

                      <ResponsiveTableRow
                        errorCellOne={errors.enquirytype}
                        errorCellTwo={errors.sourceofenquiry}
                        memberFieldCellOne={enquiry?.enquirytype}
                        memberFieldCellOneValue={enquiry?.enquirytype}
                        memberFieldCellOneOptions={enquirytypeOptions}
                        memberFieldCellTwo={enquiry?.sourceofenquiry}
                        memberFieldCellTwoValue={enquiry?.sourceofenquiry}
                        memberFieldCellTwoOptions={sourceofenquiryOptions}
                        memberFieldCellOneType="dropdown"
                        memberFieldCellTwoType="dropdown"
                        isSmallScreen={isSmallScreen}
                        // editableFields={editableFields}
                        fieldOneEditable={editableFields}
                        fieldTwoEditable={editableFields}
                        cellOneName="Enquiry Type"
                        cellTwoName="Source Of Enquiry"
                        fieldOneName="enquirytype"
                        fieldTwoName="sourceofenquiry"
                        handleInputChange={handleInputChange}
                        errorObject={newErrors}
                      />
                      <Grid
                        container
                        spacing={2}
                        sx={{ marginTop: 2, paddingLeft: "6px" }}
                        direction={{ xs: "row", sm: "column" }}
                      >
                        {/* Follow-up 1 */}
                        {props.mode === "add" ? null : (
                          <Grid item xs={12} sm={6}>
                            <Grid container alignItems="center" spacing={1}>
                              <Grid item>
                                <Checkbox
                                  checked={enquiry.followup1 || false}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      "followup1",
                                      e.target.checked
                                    )
                                  }
                                  disabled={
                                    !editableFields || followupState.followups1
                                  }
                                />
                              </Grid>
                              <Grid item xs>
                                <TextField
                                  label="Follow-up 1 Details"
                                  sx={{ width: "95%" }}
                                  value={enquiry.followup1_details || ""}
                                  onChange={(e) =>
                                    handleFollowupDetailsChange(
                                      "followup1_details",
                                      e.target.value
                                    )
                                  }
                                  disabled={
                                    !editableFields ||
                                    !enquiry.followup1 ||
                                    followupState.followup1Disabled
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}

                        {/* Follow-up 2 */}
                        {props.mode === "add" ? null : (
                          <Grid item xs={12} sm={6}>
                            <Grid container alignItems="center" spacing={1}>
                              <Grid item>
                                <Checkbox
                                  checked={enquiry.followup2 || false}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      "followup2",
                                      e.target.checked
                                    )
                                  }
                                  disabled={
                                    !editableFields || followupState.followups2
                                  }
                                />
                              </Grid>
                              <Grid item xs>
                                <TextField
                                  label="Follow-up 2 Details"
                                  sx={{ width: "95%" }}
                                  value={enquiry.followup2_details || ""}
                                  onChange={(e) =>
                                    handleFollowupDetailsChange(
                                      "followup2_details",
                                      e.target.value
                                    )
                                  }
                                  disabled={
                                    !editableFields ||
                                    !enquiry.followup2 ||
                                    followupState.followup2Disabled
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>

                      {props.mode === "add" ? (
                        ""
                      ) : (
                        <TabPanel value="1">
                          {isSmallScreen ? (
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Button
                                variant="contained"
                                size="small"
                                startIcon={<PersonAddIcon />}
                                onClick={handleConvetToMember}
                              >
                                Convert to Member
                              </Button>
                            </Grid>
                          ) : (
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Button
                                variant="contained"
                                size="small"
                                startIcon={<PersonAddIcon />}
                                onClick={handleConvetToMember}
                              >
                                Convert to Member
                              </Button>
                            </Grid>
                          )}
                        </TabPanel>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                {isSmallScreen ? (
                  <Grid
                    container
                    alignItem="center"
                    justifyContent="center"
                    sx={{ mt: 3 }}
                  >
                    <Button
                      disabled={!editcud.save}
                      variant="contained"
                      size="small"
                      startIcon={<SaveIcon />}
                      onClick={handleSaveClick}
                    >
                      Save Details
                    </Button>
                  </Grid>
                ) : (
                  ""
                )}
              </TabPanel>
            </TabContext>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};

export default ViewEnquiryDetails;
