import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllEnquiry,
  sendEnquiry,
  sentupdateEnquiry,
  getinvoice,
} from "../axiosApi/api";

const initialState = {
  value: 0,
  enquiryData: [],
  status: "idle",
  error: null,
  selectedEnquiry: {},
  sendEnquiryDataResponse: "",
  flagReset: null,
  invoice: [],
};

export const fetchEnquiryData = createAsyncThunk(
  "enquiry/fetchEnquiryData",
  async (unusedPayload, { dispatch }) => {
    try {
      dispatch(setEnquiryStatus("loading"));
      const accessToken = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await getAllEnquiry("/enquiries", { headers });
      dispatch(setEnquiryStatus("succeeded"));
      return response;
    } catch (error) {
      console.error("Error fetching enquiry data:", error);
      dispatch(setEnquiryStatus("failed"));
      throw error;
    }
  }
);

// Rename this to use a unique action type
export const invoiceData = createAsyncThunk(
  "/members/ReceiptNumber", // Changed action type to "invoice/fetchInvoiceData"
  async (unusedPayload, { dispatch }) => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await getinvoice("/members/ReceiptNumber", {
        headers,
      });
      return response;
    } catch (error) {
      console.error("Error fetching invoice data:", error);
      throw error;
    }
  }
);

export const sendEnquiryData = createAsyncThunk(
  "enquiry/sendEnquiryData",
  async (updatedData) => {
    try {
      if (typeof updatedData.id === "undefined") {
        console.log("undefined called");
        const response = await sendEnquiry(updatedData);
        return response.status;
      } else {
        const response = await sentupdateEnquiry(updatedData);
        return response.status;
      }
    } catch (error) {
      throw new Error(
        error.response.data.message || "Failed to send data to the database"
      );
    }
  }
);

export const enquirySlice = createSlice({
  name: "enquiry",
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
      console.log(state.value);
    },
    setEnquiryData: (state, action) => {
      state.enquiryData = action.payload;
    },
    addEnquiry: (state, action) => {
      state.enquiryData.push(action.payload);
    },
    setEnquiryStatus: (state, action) => {
      state.status = action.payload;
    },
    updateEnquiry: (state, action) => {
      const updatedEnquiry = action.payload;
      const index = state.enquiryData.findIndex(
        (enquiry) => enquiry.id === updatedEnquiry.id
      );
      if (index !== -1) {
        state.enquiryData[index] = updatedEnquiry;
      }
    },
    flagReset: (state, action) => {
      state.sendEnquiryDataResponse = action.payload;
    },
    setInvoiceStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEnquiryData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchEnquiryData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.enquiryData = action.payload;
      })
      .addCase(fetchEnquiryData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(sendEnquiryData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendEnquiryData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.sendEnquiryDataResponse = action.payload;
      })
      .addCase(sendEnquiryData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(invoiceData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(invoiceData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.invoice = action.payload;
      })
      .addCase(invoiceData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  increment,
  incrementByAmount,
  updateEnquiry,
  setEnquiryStatus,
  setEnquiryData,
  addEnquiry,
  flagReset,
  setInvoiceStatus,
} = enquirySlice.actions;

// Selectors
export const selectInvoiceData = (state) => state.enquiry.invoice; // Corrected to reflect the new state structure
export const selectInvoiceStatus = (state) => state.enquiry.status;

export const selectEnquiryData = (state) => state.enquiry.enquiryData;
export const selectEnquiryStatus = (state) => state.enquiry.status;
export const selectEnquiryById = (state, enquiryId) => {
  return state.enquiry.enquiryData.find(
    (enquiry) => enquiry.id === Number(enquiryId)
  );
};
export const sendEnquiryDataRes = (state) => {
  return state.enquiry.sendEnquiryDataResponse;
};
export default enquirySlice.reducer;
