import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { KeyboardArrowRight } from "@mui/icons-material";
import { Stack } from "@mui/system";
import Banner from "../pageBanner/Banner";
import {
  fetchMembershipsData,
  selectMembershipsData,
} from "../../slices/membershipsSlice";

const Membership = () => {
  const customChipLabel = {
    justifyContent: "left",
    height: "auto",
    "& .MuiChip-label": {
      whiteSpace: "normal",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
  };
  const dispatch = useDispatch();
  const membershipsData = useSelector(selectMembershipsData) || [];
  const [membershipsRows, setMembershipsRows] = useState([]);

  useEffect(() => {
    dispatch(fetchMembershipsData());
  }, [dispatch]);

  useEffect(() => {
    if (membershipsData) {
      const sortedData = [...membershipsData]
        .filter((item) => item.status)
        .sort((a, b) => b.term_months - a.term_months);

      setMembershipsRows(sortedData);
    }
  }, [membershipsData]);

  return (
    <>
      <Banner
        backgroundImage="/girl-fitness.jpg"
        title="Membership"
        subtitle="Your Journey to Fitness Excellence"
        buttonText="Join Us Today"
        showBackdrop={false}
      />
      <Box sx={{ mt: 7, mb: 12 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={1} xl={1}></Grid>
          <Grid item xs={12} md={10} xl={10}>
            <Typography
              variant="h3"
              gutterBottom
              marked="center"
              align="center"
              sx={{ px: { xl: "20%", lg: "10%" } }}
            >
              "Choose Your Membership Plan at CAPSTONE Gym"
            </Typography>
            <Typography
              component="p"
              gutterBottom
              marked="center"
              align="center"
              sx={{ px: { xl: "20%", lg: "10%" } }}
            >
              "Welcome to CAPSTONE Gym, where your fitness journey begins! We
              offer a variety of membership plans designed to accommodate your
              fitness goals, lifestyle, and budget. Explore our membership
              options below and find the perfect plan to kickstart your fitness
              journey."
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xs: "1fr",
                  sm: "1fr 1fr",
                  md: "1fr 1fr 1fr",
                },
                gap: 2,
                justifyContent: "center",
              }}
            >
              {membershipsRows
                .filter((item) => item.status)
                .map((item, index) => (
                  <Card
                    key={index}
                    sx={{
                      width: "100%",
                      maxWidth: "320px",
                      boxShadow: 3,
                      margin: "auto",
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="250"
                      image={`/gallery/m${(index % 5) + 1}.jpg`}
                      alt={item.name}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {item.name}
                      </Typography>
                      <Divider inset="none" />
                      <Stack spacing={2} my={2}>
                        <Chip
                          icon={<KeyboardArrowRight />}
                          label={`Duration: ${item.term_months} months`}
                          sx={customChipLabel}
                        />
                      </Stack>
                      <Divider inset="none" />
                      <CardActions>
                        <Typography level="title-lg" sx={{ mr: "auto" }}>
                          {`₹${item.fee}`}
                          <Tooltip title={item.terms}></Tooltip>
                        </Typography>
                      </CardActions>
                    </CardContent>
                  </Card>
                ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={1} xl={1}></Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Membership;
