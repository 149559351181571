import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllMemberships, addUpdateMembershipService } from "../axiosApi/api";

const initialState = {
  membershipsData: [],
  status: "idle",
  error: null,
  selectedMembership: {},
  flagReset: null,
  sendMembershipDataResponse: null,
};

export const fetchMembershipsData = createAsyncThunk(
  "memberships/fetchMembershipsData",
  async (unusedPayload, { dispatch }) => {
    try {
      dispatch(setMembershipsStatus("loading"));
      const accessToken = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await getAllMemberships("/memberships", { headers });
      dispatch(setMembershipsStatus("succeeded"));
      return response;
    } catch (error) {
      console.error("Error fetching memberships data:", error);
      dispatch(setMembershipsStatus("failed"));
      throw error;
    }
  }
);

export const sendMembershipData = createAsyncThunk(
  "memberships/sendMembershipData",
  async (membership) => {
    try {
      if (typeof membership.id === "undefined") {
        const response = await addUpdateMembershipService(
          "/memberships",
          membership
        );
        return response.status;
      } else {
        console.log("membership: ", membership);
        const response = await addUpdateMembershipService(
          "/memberships/updateMembership",
          membership
        );
        return response.status;
      }
    } catch (error) {
      throw new Error(
        error.response.data.message || "Failed to send data to the database."
      );
    }
  }
);

export const membershipsSlice = createSlice({
  name: "memberships",
  initialState,
  reducers: {
    setMembershipsData: (state, action) => {
      state.membershipsData = action.payload;
    },
    setMembershipsStatus: (state, action) => {
      state.status = action.payload;
    },
    addMembership: (state, action) => {
      state.membershipsData.push(action.payload);
    },

    updateMembership: (state, action) => {
      const updatedTrainer = action.payload;
      const index = state.membershipsData.findIndex(
        (trainer) => trainer.id === updatedTrainer.id
      );
      if (index !== -1) {
        state.membershipsData[index] = updatedTrainer;
      }
    },

    flagReset: (state, action) => {
      state.sendMembershipDataResponse = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMembershipsData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMembershipsData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.membershipsData = action.payload;
      })
      .addCase(fetchMembershipsData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(sendMembershipData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendMembershipData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.sendMembershipDataResponse = action.payload;
      })
      .addCase(sendMembershipData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  setMembershipsData,
  addMembership,
  setMembershipsStatus,
  updateMembership,
  flagReset,
} = membershipsSlice.actions;

export const selectMembershipsData = (state) =>
  state.memberships.membershipsData;
export const selectMembershipsStatus = (state) => state.memberships.status;
export const selectMembershipById = (state, membershipId) => {
  return state.memberships.membershipsData.find(
    (membership) => membership.id === Number(membershipId)
  );
};
export const sendMembershipDataRes = (state) => {
  return state.memberships.sendMembershipDataResponse;
};

export default membershipsSlice.reducer;
