import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import SelfImprovementRoundedIcon from "@mui/icons-material/SelfImprovementRounded";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import SettingsIcon from "@mui/icons-material/Settings";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
// icons
const icons = {
  DashboardIcon,
  GroupsIcon,
  ContactMailIcon,
  SelfImprovementRoundedIcon,
  SettingsIcon,
  CardMembershipIcon,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: "group-dashboard",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "navigationMenuItem",
      url: "/adminapp",
      icon: icons.DashboardIcon,
      breadcrumbs: false,
    },
  ],
};

const administrative = {
  id: "administrative",
  type: "group",
  children: [
    {
      id: "enquiries",
      title: "Enquiries",
      type: "navigationMenuItem",
      url: "/adminapp/enquiries",
      icon: icons.ContactMailIcon,
    },
    {
      id: "members",
      title: "Members",
      type: "navigationMenuItem",
      url: "/adminapp/members",
      icon: icons.GroupsIcon,
    },
    {
      id: "trainers",
      title: "Trainers",
      type: "navigationMenuItem",
      url: "/adminapp/trainers",
      icon: icons.SelfImprovementRoundedIcon,
    },
    {
      id: "membership",
      title: "Memberships",
      type: "navigationMenuItem",
      url: "/adminapp/memberships",
      icon: icons.CardMembershipIcon,
    },
    {
      id: "settings",
      title: "Settings",
      type: "navigationMenuItem",
      url: "/adminapp/settings",
      icon: icons.SettingsIcon,
    },
  ],
};

const menuItems = {
  items: [dashboard, administrative],
};

export default menuItems;
