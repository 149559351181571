import { Grid, Typography } from "@mui/material";
import AvatarDropDown from "../AvatarDropDown";
import { Box } from "@mui/system";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  return (
    <Grid
      container
      maxWidth="100%"
      sx={{ display: "flex", alignItems: "center" }}
    >
      <Box sx={{ display: "flex", alignItems: "center", width: "80%" }}>
        <Box
          component="img"
          src="capstone192.png"
          alt="Logo"
          sx={{ height: 40, marginRight: 1 }}
        />

        <Typography
          variant="h4"
          fontWeight={500}
          sx={{
            fontSize: {
              xs: "1.2rem",
              sm: "1.5rem",
              md: "1.75rem",
            },
          }}
        >
          Capstone Admin
        </Typography>
      </Box>
      <Box sx={{ width: "20%" }}>
        <AvatarDropDown />
      </Box>
    </Grid>
  );
};

export default HeaderContent;
