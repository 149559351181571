import React, { useState } from "react";
import "./quickEnquiry.css";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { incrementByAmount } from "../../slices/enquirySlice";
import { setFocusOnInputField } from "../../slices/focusInputSlice";
import { sendEnquiry } from "../../axiosApi/api";
import { interestOptions } from "./constant";
import { enquiryDate } from "./constant";

const QuickEnquiry = () => {
  const dispatch = useDispatch();
  const focusedName = useSelector((state) => state.focus.focusedTextField);
  const [formData, setFormData] = useState({
    name: "",
    email_id: "",
    contact_no: "",
    interest: "",
    comment: "",
    enquiry_date: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email_id: "",
    contact_no: "",
    interest: "",
  });
  // snackbar
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  // custom snackbar
  const [notification, setNotification] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      enquiry_date: enquiryDate,
    }));
  };
  const handleTextFieldFocus = () => {
    dispatch(setFocusOnInputField("name"));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform validation
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email_id) newErrors.email_id = "Email is required";
    if (!formData.contact_no)
      newErrors.contact_no = "Contact number is required";
    if (!formData.interest) newErrors.interest = "Select an Interest";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        console.log(formData);
        const res = await sendEnquiry(formData);
        console.log(res.status);
        if (res.status === 208) {
          setOpen(true);
          setNotification({
            variant: "error",
            message: "Enquiry Already Existed.",
          });
        } else if (res.status === 200) {
          setOpen(true);
          setNotification({
            variant: "success",
            message: "Enquiry Submitted Successfully.",
          });
        }

        setFormData({
          name: "",
          email_id: "",
          contact_no: "",
          interest: "",
          comment: "",
        });

        dispatch(incrementByAmount(1));
      } catch (error) {
        console.error("Error enquiry:", error.message);
      }
    }
  };

  return (
    <div
      id="enquiry"
      style={{
        backgroundImage: `url('enquery.jpg')`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        minHeight: "320px",
        flexDirection: "column",
        justifyContent: "space-around",
        padding: "50px 20px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={7}></Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ boxShadow: "none", my: 10 }}>
            <CardHeader title="Get in touch" />
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid item container spacing={1} justify="center">
                  <Grid item xs={12}>
                    <TextField
                      name="name"
                      label="Name"
                      value={formData.name}
                      onChange={handleChange}
                      fullWidth
                      size="small"
                      error={!!errors.name}
                      helperText={errors.name}
                      onFocus={handleTextFieldFocus}
                      autoFocus={focusedName === "name"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="email_id"
                      label="Email"
                      type="email_id"
                      value={formData.email_id}
                      onChange={handleChange}
                      fullWidth
                      size="small"
                      error={!!errors.email_id}
                      helperText={errors.email_id}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="contact_no"
                      label="Contact Number"
                      type="text"
                      value={formData.contact_no}
                      onChange={handleChange}
                      fullWidth
                      size="small"
                      error={!!errors.contact_no}
                      helperText={errors.contact_no}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth error={!!errors.interest}>
                      <InputLabel>Select Interest</InputLabel>
                      <Select
                        name="interest"
                        value={formData.interest}
                        onChange={handleChange}
                        label="Select Interest"
                        size="small"
                      >
                        {interestOptions.map((option) => {
                          return <MenuItem value={option}>{option}</MenuItem>;
                        })}
                      </Select>
                      {errors.interest && <p>{errors.interest}</p>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="comment"
                      label="Description"
                      multiline
                      rows={4}
                      value={formData.comment}
                      onChange={handleChange}
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                    >
                      Submit
                    </Button>
                    <Snackbar
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity={notification.variant}
                        variant="filled"
                        sx={{ width: "100%" }}
                      >
                        {notification.message}
                      </Alert>
                    </Snackbar>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={1}></Grid>
      </Grid>
    </div>
  );
};

export default QuickEnquiry;
