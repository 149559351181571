import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Markdown from "../Markdown";

const markdownContent = `
<!-- markdownlint-capture -->
<!-- markdownlint-disable -->

Last modified: October 1st, 2023.

Welcome to CAPSTONE Gym! These Terms and Conditions govern your use of our website and services. By using our website and services, you agree to comply with these terms. Please read them carefully.

<!-- markdownlint-restore -->

## 1. Eligibility

- 1.1. You must be at least 18 years old to use our services. By using our services, you confirm that you meet this age requirement.

## 2. Use of Our Services

- 2.1. You agree to use our services for lawful purposes and in compliance with these terms. You are responsible for maintaining the security of your account and for all activities conducted under your account.

- 2.2. You agree not to:

Use our services for any illegal or unauthorized purpose.
Violate any applicable laws or regulations.
Post or share content that is harmful, offensive, or violates the rights of others.
Attempt to access, tamper with, or use non-public areas of our website and services.

## 3. Fees and Payments

- 3.1. Our services may require payment, and you agree to pay the fees associated with your use of these services. Payments are processed securely, and we do not store your payment card information.

## 4. Termination

- 4.1. We reserve the right to suspend or terminate your account and access to our services if you violate these terms or engage in prohibited activities.

## 5. User Content

- 5.1. By using our services, you may post content, including text, images, and other materials. You retain ownership of your content, but you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, and distribute your content for the purpose of providing and improving our services.

- 5.2. You are responsible for the content you post and must ensure it complies with these terms and applicable laws. We may remove or block content that violates these terms.

## 6. Disclaimers

- 6.1. CAPSTONE Gym is not responsible for any injuries or health-related issues that may arise from your use of our services. Always consult with a medical professional before starting any fitness program.

## 7. Limitation of Liability

- 7.1. To the extent permitted by law, CAPSTONE Gym and its affiliates are not liable for any direct, indirect, incidental, special, or consequential damages resulting from your use of our services.

## 8. Changes to Terms

- 8.1. We may update these terms from time to time, and any changes will be posted on our website. Your continued use of our services after changes to the terms signify your acceptance of those changes.

## 9. Governing Law

- 9.1. These terms are governed by the laws of [Your Jurisdiction]. Any disputes related to these terms or our services shall be resolved in the courts of [Your Jurisdiction].

## 10. Contact Us

- 10.1. If you have any questions or concerns about our Terms and Conditions, please contact us at [Contact Information].

`;

export const Terms = () => {
  return (
    <Container>
      <Box sx={{ mt: 7, mb: 12 }}>
        <Typography variant="h3" gutterBottom marked="center" align="center">
          Terms & Conditions
        </Typography>
        <Markdown>{markdownContent}</Markdown>
      </Box>
    </Container>
  );
};
