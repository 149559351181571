import React, { lazy, Suspense } from "react";
import "./App.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import theme from "./theme/Theme";
import Layout from "./components/public/Layout";
import Home from "./components/public/Home";
import Membership from "./components/public/Membership";
import PersonalTrainingProgramme from "./components/public/program/PersonalTraining";
import SelfTrainingProgramme from "./components/public/program/SelfTraining";
import AboutUs from "./components/public/AboutUs";
import Contact from "./components/public/Contact";
import Login from "./components/public/Login";
import { Terms } from "./components/footer/Terms";
import { Privacy } from "./components/footer/Privacy";
import Dashboard from "./components/admin/pages/dashboard/Dashboard";
import MainLayout from "./components/admin/layout/MainLayout";
import Members from "./components/admin/pages/members/Members";
import Trainers from "./components/admin/pages/trainers/Trainers";
import Enquiries from "./components/admin/pages/enquiries/Enquiries";
import Settings from "./components/admin/pages/settings/Settings";
import ViewEnquiryDetails from "./components/admin/pages/enquiries/ViewEnquiryDetails";
import ViewMemberDetails from "./components/admin/pages/members/ViewMemberDetails";
import AdminDetails from "./components/admin/pages/profile/AdminDetails";
import ViewTrainerDetails from "./components/admin/pages/trainers/ViewTrainerDetails";
import Memberships from "./components/admin/pages/memberships/Memberships";
import MembershipDetails from "./components/admin/pages/memberships/ViewMembershipDetails";
import ViewMembershipDetails from "./components/admin/pages/memberships/ViewMembershipDetails";
const Events = lazy(() => import("./components/public/Events"));

function App() {
  const ProtectedRoute = ({ element, ...rest }) => {
    const isAuthenticated = !!localStorage.getItem("accessToken");

    return isAuthenticated ? element : <Navigate to="/login" />;
  };
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/enquiry" element={<Home />} />
            <Route
              path="/events"
              element={
                <Suspense
                  fallback={
                    <>
                      <h1>Please Wait...</h1>
                    </>
                  }
                >
                  <Events />
                </Suspense>
              }
            />
            <Route path="/membership" element={<Membership />} />
            <Route
              path="/personaltrainingprogramme"
              element={<PersonalTrainingProgramme />}
            />
            <Route
              path="/selftrainingprogramme"
              element={<SelfTrainingProgramme />}
            />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="*" element={<Home />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route
            path="/adminapp/*"
            element={<ProtectedRoute element={<MainLayout />} />}
          >
            <Route index element={<Dashboard />} />
            <Route path="adminprofile" element={<AdminDetails />} />
            <Route path="members" element={<Members />} />
            <Route path="memberDetails/:id" element={<ViewMemberDetails />} />
            <Route
              path="memberDetails/"
              element={<ViewMemberDetails mode="add" />}
            />
            <Route
              path="memberDetails/:data"
              element={<ViewMemberDetails mode="add" />}
            />
            <Route path="trainers" element={<Trainers />} />
            <Route path="trainerDetails/:id" element={<ViewTrainerDetails />} />
            <Route
              path="trainerDetails/"
              element={<ViewTrainerDetails mode="add" />}
            />
            <Route path="enquiries" element={<Enquiries />} />
            <Route path="enquiryDetails/:id" element={<ViewEnquiryDetails />} />
            <Route
              path="enquiryDetails/"
              element={<ViewEnquiryDetails mode="add" />}
            />
            <Route path="memberships" element={<Memberships />} />
            <Route path="membershipDetails/:id" element={<ViewMembershipDetails/>}/>
            <Route
              path="membershipDetails/"
              element={<MembershipDetails mode="add" />}
            />
            <Route path="settings" element={<Settings />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
