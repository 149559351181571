import {
  Typography,
  Stack,
  Grid,
  Switch,
  FormControlLabel,
  Divider,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  sendQuestionsData,
  selectAnswerByMemberId,
  fetchAnswers,
} from "../../../../slices/membersSlice";
import { getAllQuestions } from "../../../../axiosApi/api";
import CustomDialog from "../../../common/CustomDialog";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";

const Questionnaire = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedAnswer = useSelector((state) =>
    selectAnswerByMemberId(state, id)
  );
  console.log("selected answer of member: ", selectedAnswer);
  const [questions, setQuestions] = useState([]);
  const [handleAnswer, setHandleAnswer] = useState(false);
  const [questionStates, setQuestionStates] = useState({});
  const [editcud, setEditcud] = useState({
    save: false,
    update: true,
  });
  const [editableFields, setEditableFields] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [notification, setNotification] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    dispatch(fetchAnswers());
  }, [handleAnswer]);

  useEffect(() => {
    if (props.mode === "add") {
      setEditableFields(true);
      setEditcud({
        update: false,
        save: true,
      });
    } else {
      if (selectedAnswer.length > 0) {
        const transformedData = selectedAnswer.reduce((acc, obj) => {
          acc[obj.questionid] = obj.answer;
          return acc;
        }, {});
        console.log("transformed data: ", transformedData);
        setQuestionStates(transformedData); //take answer from backend
      }
    }
  }, []);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleBacktoMember = () => {
    setDialogOpen(true);
  };
  const handleNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotification(false);
  };

  const handleConfirmForBacktoMember = () => {
    // setQuestionStates();
    navigate("/adminapp/members");
  };

  const fetchData = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await getAllQuestions({ headers });
      if (props.mode === "add") {
        const initialQuestionStates = response.reduce((acc, question) => {
          acc[question.id] = false;
          return acc;
        }, {});
        console.log("initialQuestionStates ", initialQuestionStates);
        setQuestionStates(initialQuestionStates);
      } else {
      }

      setQuestions(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (questionId, newValue) => {
    setQuestionStates((prevStates) => ({
      ...prevStates,
      [questionId]: newValue,
    }));
  };

  const handletoggle = (questionId) => {
    const newValue = !questionStates[questionId];
    handleChange(questionId, newValue);
  };

  const handleUpdateClick = () => {
    setEditableFields(true);
    setEditcud({
      save: true,
    });
  };
  const handleSaveClick = (e) => {
    e.preventDefault();
    console.log("questionStates: ", questionStates);
    console.log("props in handleSave: ", props.mode);
    handleAnswer ? setHandleAnswer(false) : setHandleAnswer(true);
    console.log("handleAnswer value: ", handleAnswer);
    if (props.mode === "add") {
      dispatch(
        sendQuestionsData({ answer: questionStates, memberid: props.memberId })
      );
      navigate("/adminapp/members");
    } else {
      dispatch(sendQuestionsData({ answer: questionStates, memberid: id }));
    }
    setEditableFields(false);
    setEditcud({
      update: true,
    });
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Stack spacing={2} direction={{ xs: "column", sm: "row", md: "row" }}>
          <Button
            variant="contained"
            size="small"
            disabled={!editcud.update}
            startIcon={<EditIcon />}
            onClick={handleUpdateClick}
          >
            Update
          </Button>
          {props.screenSize ? (
            " "
          ) : (
            <Button
              disabled={!editcud.save}
              variant="contained"
              size="small"
              startIcon={<SaveIcon />}
              onClick={handleSaveClick}
            >
              Save Details
            </Button>
          )}
          {
            <CustomDialog
              open={dialogOpen}
              handleClose={handleDialogClose}
              onConfirm={handleConfirmForBacktoMember}
              content={
                "Some of the details are unsaved. Do you want to go back without saving ?"
              }
            />
          }
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={openNotification}
            autoHideDuration={6000}
            onClose={handleNotificationClose}
          >
            <Alert
              onClose={handleNotificationClose}
              severity={notification.variant}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {notification.message}
            </Alert>
          </Snackbar>
        </Stack>
        <Stack direction="row" sx={{ mt: { xs: "1rem", sm: "0" } }}>
          <Button
            variant="contained"
            size="small"
            startIcon={<PersonIcon />}
            onClick={() =>
              editcud.save === true
                ? handleBacktoMember()
                : navigate("/adminapp/members")
            }
          >
            Back to Member
          </Button>
        </Stack>
      </Grid>
      <Divider sx={{ my: 3 }} />
      {questions.map((data) => (
        <Grid key={data.id}>
          {" "}
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>
              {data.id}) {data.question}
            </Typography>
            <FormControlLabel
              label={questionStates[data.id] ? "Yes" : "NO"}
              control={
                <Switch
                  checked={questionStates[data.id]}
                  onChange={() => handletoggle(data.id)}
                  color="primary"
                  inputProps={{ "aria-label": "toggle switch" }}
                  disabled={!editableFields}
                />
              }
            />
          </Grid>
          <Divider sx={{ m: "5px 0" }} />
        </Grid>
      ))}
      <Grid sx={{ mt: 3, color: "red" }}>
        <Typography>
          Note: Please read all questions carefully and provide appropriate
          answers.
        </Typography>
      </Grid>
      <Grid container justifyContent={"center"} sx={{ mt: 2 }}>
        {props.screenSize ? (
          <Button
            disabled={!editcud.save}
            variant="contained"
            size="small"
            startIcon={<SaveIcon />}
            onClick={handleSaveClick}
          >
            Save Details
          </Button>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};
export default Questionnaire;
