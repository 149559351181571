import React from "react";
import { Container, Grid, Paper, Typography } from "@mui/material";
import Banner from "../pageBanner/Banner";

const AboutUs = () => {
  return (
    <>
      <Banner
        backgroundImage="/gallery/image06.jpg"
        title="Discover CAPSTONE Gym"
        subtitle="Your Journey to Fitness Excellence"
        overlayColor="rgba(84, 77, 11, 0.5)"
      />
      <Container sx={{ py: 4 }}>
        <Typography variant="h3" gutterBottom>
          The CAPSTONE :
        </Typography>
        <Typography variant="body1" paragraph>
          "Welcome to CAPSTONE Gym, where fitness meets dedication, and health transforms into a
          lifestyle. Our story is one of passion, commitment, and a genuine desire to help you
          achieve your fitness goals. Discover who we are, our mission, and what makes CAPSTONE
          Gym a unique fitness destination."
        </Typography>
        <Typography variant="body1" paragraph>
          Nulla facilisi. Nullam nec rhoncus nunc. Curabitur placerat, libero quis efficitur
          feugiat, arcu metus varius dui, nec lacinia justo odio id metus. Sed pharetra, dui ac
          ultricies iaculis, arcu nunc pretium lorem, eu feugiat neque elit a mi.
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Our Mission
              </Typography>
              <Typography variant="body2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus bibendum justo
                vel ex aliquam, vel laoreet dolor semper.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Our Values
              </Typography>
              <Typography variant="body2">
                Integer nec justo ut orci tincidunt hendrerit. Cras et consectetur quam, vel
                sollicitudin dui. Nam et felis vel dolor posuere vestibulum.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AboutUs;
