import React from "react";

import { Outlet } from "react-router-dom";
import Headers from "./Headers";
import Footer from "../footer/Footer";

const Layout = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Headers />
      <main style={{ flex: 1 }}>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
