import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import "./ViewMemberDetails.css";
import {
  selectMemberById,
  fetchMembersData,
  selectMembersData,
  sendMembersData,
  sendMemberDataRes,
  fetchMemberMembership,
  selectMembersStatus,
} from "../../../../slices/membersSlice";

import { fetchMembershipsData } from "../../../../slices/membershipsSlice";

import { selectMembershipsData } from "../../../../slices/membershipsSlice";

import { selectMembersMembershipData } from "../../../../slices/membersSlice";

import {
  fetchTrainersData,
  selectTrainersData,
} from "../../../../slices/trainersSlice";
import {
  Box,
  Grid,
  Typography,
  Button,
  Avatar,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Stack,
  Snackbar,
  Alert,
  Chip,
  FormControlLabel,
} from "@mui/material";
import CustomDialog from "../../../common/CustomDialog";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import CancelIcon from "@mui/icons-material/Cancel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CameraIcon from "@mui/icons-material/CameraAlt";
import SaveIcon from "@mui/icons-material/Save";
import styled from "@emotion/styled";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { flagReset, fetchAnswers } from "../../../../slices/membersSlice";
import ResponsiveTableRow from "../../../common/ResponsiveTableRow";
import { bloodGroups, genders } from "./memberConstant";
import Questionnaire from "./Questionnaire";
import CapturePhotoNonMobileDialog from "./CapturePhotoNonMobileDialog";
import MembershipDetails from "./MemberMembershipDetails";
import DownloadIcon from "@mui/icons-material/Download";
import jsPDF from "jspdf";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "unset",
  },
  "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "0px",
  },
}));

const ViewMemberDetails = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { mode } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedMember = useSelector((state) => selectMemberById(state, id));

  const membersData = useSelector(selectMembersData);

  const sendmemberres = useSelector(sendMemberDataRes);
  const [queMemberId, setQueMemberId] = useState();
  const [questionnaire, setQuestionnaire] = useState(false);
  const [handleUpdate, setHandleUpdate] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);

  const [openCapturePhotoDialog, setOpenCapturePhotoDialog] =
    React.useState(false);
  const [member, setMember] = useState({
    name: "",
    gender: "",
    contact_no: "",
    email_id: "",
    address: "",
    blood_group: "",
    date_of_birth: "",
    emergency_no: "",
    occupation: "",
    profilePhoto: " ",
    status: false,
  });
  const membersStatus = useSelector(selectMembersStatus);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotification(false);
  };
  const [notification, setNotification] = useState({});
  const [identifyDialog, setIdentifyDialog] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const membersMembershipData = useSelector(selectMembersMembershipData);
  const trainersData = useSelector(selectTrainersData);
  const membershipsData = useSelector(selectMembershipsData);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleConfirmForCancel = () => {
    if (mode === "add") {
      setMember({
        name: "",
        gender: "",
        contact_no: "",
        email_id: "",
        address: "",
        blood_group: "",
        date_of_birth: "",
        emergency_no: "",
        occupation: "",
        profilePhoto: " ",
        status: true,
        avatar: "",
      });
      setEditableFields(true);
      setEditcud({
        save: true,
        cancel: true,
        update: false,
      });
      setErrors({});
    } else {
      setMember(selectedMember);
      setEditableFields(false);
      setEditcud({
        save: false,
        cancel: false,
        update: true,
      });
    }
    setDialogOpen(false);
  };

  const handleConfirmForBacktoMember = () => {
    setMember(selectedMember);
    navigate("/adminapp/members");
  };

  const [errors, setErrors] = useState({
    name: "",
    email_id: "",
    contact_no: "",
    date_of_birth: "",
    address: "",
    gender: "",
  });
  const [tabsHeadValue, setTabsValueHead] = useState("1");

  const [editableFields, setEditableFields] = useState(false);

  const [editcud, setEditcud] = useState({
    save: false,
    cancel: false,
    update: true,
  });

  const [selectedMemberId, setSelectedMemberId] = useState(null);

  const members = useSelector((state) =>
    selectMembersData(state).filter((member) => member.status === "Active")
  );

  const handletoggle = (value) => {
    setSelectedMemberId((prevId) => (prevId === value ? null : value));
  };

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setIsMobileDevice(true);
    } else {
      setIsMobileDevice(false);
    }
  }, []);

  useEffect(() => {
    dispatch(fetchTrainersData());

    dispatch(fetchMembershipsData());
  }, [dispatch]);

  useEffect(() => {
    if (membersStatus === "idle") {
      dispatch(fetchMembersData());
    }
    dispatch(fetchAnswers());
    if (id) {
      dispatch(fetchMemberMembership(id));
    }
  }, [dispatch, id, handleUpdate]);

  useEffect(() => {
    if (mode === "add") {
      setEditableFields(true);
      setEditcud({
        save: true,
        cancel: true,
        update: false,
      });
      const convertMember = location.state?.data;
      setMember({
        ...member,
        name: convertMember?.name ? convertMember?.name : member.name,
        email_id: convertMember?.email_id
          ? convertMember?.email_id
          : member.email_id,
        contact_no: convertMember?.contact_no
          ? convertMember?.contact_no
          : member.contact_no,
      });
    } else {
      const member = membersData.find((member) => member.id === Number(id));
      setMember(member);
    }
  }, [membersData]);

  useEffect(() => {
    if (sendmemberres.status === 200) {
      setOpenNotification(true);
      setNotification({
        variant: "success",
        message: "Member Details Submitted Successfully.",
      });
      setQueMemberId(sendmemberres.data.latestIdNum);
      setMember({ ...member, id: sendmemberres.data.latestIdNum });
      dispatch(flagReset("0"));
      dispatch(fetchMembersData());
      setQuestionnaire(true);
      setTabsValueHead("2");
    } else if (sendmemberres.status === 208) {
      setOpenNotification(true);
      setNotification({
        variant: "error",
        message: "Member Details Already Exist.",
      });
      setEditableFields(true);
      setEditcud({
        save: true,
        cancel: true,
        update: true,
      });
      dispatch(flagReset("0"));
      setQuestionnaire(false);
    }
  }, [sendmemberres, selectedMember]);

  useEffect(() => {
    console.log("ajinkya", membershipsData);
  }, [membershipsData]);

  const setAvatar = (avatar) => {
    const updatedMember = { ...member, avatar: avatar };
    setMember(updatedMember);
  };

  const handleChange = (event, newValue) => {
    if (mode === "add") {
      if (questionnaire === true) setTabsValueHead(newValue);
    } else {
      setTabsValueHead(newValue);
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const CustomTab = styled(Tab)(({ theme }) => ({
    position: "relative",
    overflow: "visible",
    "&.Mui-selected": {
      overflow: "visible",
      backgroundColor: "#242105",
      color: "#ffeb3b",
      "&::before": {
        content: '""',
        position: "absolute",
        top: "90%",
        left: "50%",
        transform: "translateX(-50%)",
        width: 0,
        height: 0,
        borderTop: `8px solid ${theme.palette.primary.main}`,
        borderLeft: "8px solid transparent",
        borderRight: "8px solid transparent",
        marginTop: -4,
        zIndex: 1,
        overflow: "visible",
      },
    },
  }));

  const commonStyleForInput = {
    label: {
      shrink: true,
      style: { fontWeight: "bold", textTransform: "uppercase", color: "" },
    },
    input: {
      style: { color: "#242105" },
    },
  };

  const handleUpdateClick = () => {
    setEditableFields(true);
    setEditcud({
      save: true,
      cancel: true,
    });
  };

  const trainers = trainersData
    ? trainersData.map((trainer) => ({
        id: trainer.id,
        name: trainer.name,
      }))
    : [];

  const getTrainerName = (id) => {
    const trainer = trainers.find((trainer) => trainer.id === id);
    return trainer ? trainer.name : "N/A";
  };

  // const getmembershipsData = (id) => {
  //   const membership = memberships.find((membership) => membership.id === id);
  //   return membership ? membership : { name: "N/A", fee: "N/A", term_months: "N/A" };
  // };

  // const memberships = useSelector((state) => state.memberships.data);

  // const handleDownload = () => {
  //   const imgPath = "/capstone512.png"; // Path to your image in the public folder
  //   const imgElement = new Image();
  //   imgElement.src = imgPath;

  //   // Step 3: Load and convert the image to Base64
  //   imgElement.onload = () => {
  //     // Create a canvas to convert the image to Base64
  //     const canvas = document.createElement("canvas");
  //     const context = canvas.getContext("2d");
  //     canvas.width = imgElement.width;
  //     canvas.height = imgElement.height;
  //     context.drawImage(imgElement, 0, 0);

  //     // Convert the image to a Base64 string
  //     const base64Image = canvas.toDataURL("image/png");

  //     // Step 4: Create a jsPDF document and add the logo image
  //     const doc = new jsPDF();

  //     // Add the logo image
  //     doc.addImage(base64Image, "PNG", 10, 10, 30, 30); // Adjust x, y, width, height

  //     // Add Header
  //     // Header Section with static data
  //     doc.setFontSize(12);
  //     doc.setFont("helvetica", "bold");
  //     doc.text("CAPSTONE SPORTS AND FITNESS-Pradhikaran", 50, 20);

  //     doc.setFontSize(8);
  //     doc.setFont("helvetica", "normal");
  //     doc.text("Sector 27/A, Plot No. 209,", 50, 25);
  //     doc.text("Near Sambhaji Chowk, Pradhikaran, Pune 411044", 50, 30);
  //     doc.text("Pradhikaran", 50, 35);
  //     doc.text("8888188855", 50, 40);

  //     // Dynamic Date
  //     const invoiceDate = new Date().toLocaleDateString("en-GB", {
  //       day: "2-digit",
  //       month: "short",
  //       year: "numeric",
  //     });

  //     doc.setFontSize(10);
  //     doc.setFont("helvetica", "bold");
  //     doc.text("Invoice Date:", 158, 20);
  //     doc.setFont("helvetica", "normal");
  //     doc.text(invoiceDate, 180, 20);

  //     // Draw header line
  //     doc.line(10, 45, 200, 45);

  //     // Dynamically insert member details
  //     doc.setFontSize(10);
  //     doc.setFont("helvetica", "normal");
  //     doc.text(`Id: ${selectedMember?.id || "N/A"}`, 10, 50);
  //     doc.text(`Name: ${selectedMember?.name || "N/A"}`, 10, 55);
  //     doc.text(`Contact: ${selectedMember?.contact_no || "N/A"}`, 10, 60);
  //     doc.text(`Email: ${selectedMember?.email_id || "N/A"}`, 100, 50);
  //     doc.text(`GST No: ${selectedMember?.gst_number || "N/A"}`, 100, 55);

  //     doc.line(10, 65, 200, 65);

  //     // Table Header
  //     const startX = 10;
  //     const startY = 70;
  //     const cellWidth = 47.5;
  //     const cellHeight = 10;

  //     doc.setFontSize(10);
  //     doc.setFont("helvetica", "bold");
  //     doc.setDrawColor(150);

  //     //       const formatDate = (dateString) => {
  //     //   if (!dateString) return "N/A";
  //     //   const date = new Date(dateString);
  //     //   return date.toLocaleDateString("en-GB", {
  //     //     day: "2-digit",
  //     //     month: "short",
  //     //     year: "numeric",
  //     //   });
  //     // };

  //     // Fetch dynamic data for the table
  //     const tableData = [
  //       [
  //         "Package",
  //         membersMembershipData?.name || "N/A",
  //         "Amount",
  //         membersMembershipData?.amount || "N/A",
  //       ],
  //       [
  //         "Duration",
  //         membersMembershipData?.term_months || "N/A",
  //         "Discount",
  //         membersMembershipData?.discount || "N/A",
  //       ],
  //       // ["Start Date", formatDate(membersMembershipData?.membership_startdate)  || "N/A", "Reg Fees", membershipsData?.fee || "N/A"],
  //       // ["End Date", formatDate(membersMembershipData?.membership_enddate) ||"N/A", "Total Amount", membersMembershipData?.total_amount || "N/A"],
  //       [
  //         "Start Date",
  //         membersMembershipData?.membership_startdate || "N/A",
  //         "Reg Fees",
  //         membersMembershipData?.fee || "N/A",
  //       ],
  //       [
  //         "End Date",
  //         membersMembershipData?.membership_enddate || "N/A",
  //         "Total Amount",
  //         membersMembershipData?.total_amount || "N/A",
  //       ],
  //       [
  //         "Time",
  //         membersMembershipData?.time || "N/A",
  //         "Paid Amount",
  //         membersMembershipData?.paid_amount || "N/A",
  //       ],
  //       [
  //         "Instructor",
  //         getTrainerName(membersMembershipData?.trainer_id) || "N/A",
  //         "Balance",
  //         membersMembershipData?.balance || "N/A",
  //       ],
  //     ];
  //     // Populate table with data
  //     doc.setFont("helvetica", "normal");
  //     doc.setDrawColor(150);
  //     tableData.forEach((row, rowIndex) => {
  //       const rowY = startY + rowIndex * cellHeight;
  //       row.forEach((cellText, colIndex) => {
  //         doc.text(
  //           cellText.toString(),
  //           startX + colIndex * cellWidth + 5,
  //           rowY + 5
  //         );
  //         doc.rect(startX + colIndex * cellWidth, rowY, cellWidth, cellHeight); // Draw cell border
  //       });
  //     });

  //     // Footer Section
  //     doc.line(10, 130, 200, 130);
  //     doc.setFontSize(10);
  //     doc.setFont("helvetica", "bold");
  //     doc.text("Payment Transaction:", 10, 135);

  //     // Payment Details Table
  //     // Payment Details Table with minimized width
  //     const transactionData = [
  //       [
  //         "Receipt No",
  //         "Date",
  //         "Subtotal",
  //         "Tax",
  //         "CGST",
  //         "SGST",
  //         "Tax Amt",
  //         "Paid Amt",
  //         "Mode",
  //         "Exec",
  //       ],
  //       [
  //         selectedMember?.receipt_no || "N/A",
  //         selectedMember?.payment_date || "N/A",
  //         selectedMember?.subtotal || "N/A",
  //         selectedMember?.tax || "N/A",
  //         selectedMember?.cgst || "N/A",
  //         selectedMember?.sgst || "N/A",
  //         selectedMember?.tax_amount || "N/A",
  //         selectedMember?.paid_amount || "N/A",
  //         selectedMember?.payment_mode || "N/A",
  //         selectedMember?.executive || "N/A",
  //       ],
  //     ];

  //     const paymentCellWidth = 19; // Adjusted width for fitting cells
  //     const paymentCellHeight = 10; // Define cell height for consistency
  //     let paymentStartY = 140;
  //     const StartX = 10; // Initial X position for the table

  //     doc.setFont("helvetica", "normal"); // Set font to normal (non-bold)

  //     transactionData.forEach((row, rowIndex) => {
  //       const rowY = paymentStartY + rowIndex * paymentCellHeight;
  //       row.forEach((cellText, colIndex) => {
  //         const cellX = startX + colIndex * paymentCellWidth;

  //         // Center text horizontally and vertically
  //         const textX = cellX + paymentCellWidth / 2; // Horizontal center
  //         const textY = rowY + paymentCellHeight / 2 + 1.5; // Vertical center adjustment

  //         // Draw text in the center of each cell
  //         doc.text(cellText.toString(), textX, textY, { align: "center" });

  //         // Draw cell border
  //         doc.rect(cellX, rowY, paymentCellWidth, paymentCellHeight);
  //       });
  //     });
  //     // Terms and Conditions Section
  //     const termsStartY = 170; // Starting Y position for terms
  //     const termsBoxHeight = 45; // Adjust height to fit all terms text
  //     const termsBoxWidth = 190; // Width of the terms box

  //     // Draw a grey box for the Terms and Conditions section
  //     doc.setDrawColor(150); // Set border color to grey
  //     doc.rect(10, termsStartY - 5, termsBoxWidth, termsBoxHeight); // Create box around terms

  //     // Add Terms and Conditions header inside the box
  //     doc.setFontSize(12);
  //     doc.setFont("helvetica", "bold");
  //     doc.text("Terms and Conditions:", 15, termsStartY);

  //     // Add Terms and Conditions content
  //     doc.setFont("helvetica", "normal");
  //     doc.setFontSize(10);
  //     const terms = [
  //       "1) Members with any health complaints are required to provide a doctor’s certificate before participating.",
  //       "2) Members assume full responsibility for any injuries or health issues incurred during their use of gym facilities.",
  //       "3) Members are responsible for the cost of any damage they cause to gym property.",
  //       "4) The gym is not liable for the loss of personal valuables or belongings.",
  //       "5) Membership fees are strictly non-refundable and non-transferable.",
  //       "6) Memberships cannot be frozen, extended, or transferred under any circumstances.",
  //       "7) Members are required to bring appropriate sportswear, shoes, a napkin, and a water bottle for their sessions.",
  //     ];

  //     terms.forEach((term, index) => {
  //       doc.text(term, 15, termsStartY + 5 + index * 5); // Adjust text position inside the box
  //     });
  //     // Save the PDF
  //     doc.save("Member_Details.pdf");

  //     imgElement.onerror = () => {
  //       console.error("Image not found at the specified path");
  //     };
  //   };
  // };

  const handleInputChange = (field, value, type) => {
    if (errors[field]) {
      const removeError = Object.fromEntries(
        Object.entries(errors).filter(([k]) => k !== field)
      );
      setErrors(removeError);
    }

    switch (type) {
      case "text":
        if (field === "contact_no" || field === "emergency_no") {
          const regex = /^[0-9]*$/;
          if (regex.test(value) && value.length <= 10) {
            const updatedMember = {
              ...member,
              [field]: value,
            };
            setMember(updatedMember);
          }
        } else {
          const updatedMember = {
            ...member,
            [field]: value,
          };
          setMember(updatedMember);
        }
        break;

      case "switch":
        if (field === "status") {
          const updatedMember = {
            ...member,
            [field]: value,
          };
          setMember(updatedMember);
        }
        break;

      default:
        const updatedMember = {
          ...member,
          [field]: value,
        };
        setMember(updatedMember);
        break;
    }
  };

  const handleCancelClick = () => {
    setDialogOpen(true);
    setIdentifyDialog(true);
  };

  const handleSaveClick = (e) => {
    e.preventDefault();

    const phoneRegex = /^[0-9]{10}$/;
    const emailPattern = /^[a-zA-Z][^\s@]*@[^\s@]+\.[^\s@]+$/;
    const newErrors = {};
    if (!member?.name) newErrors.name = "Name is required";
    if (!emailPattern.test(member?.email_id))
      newErrors.email_id = "Please enter a valid email Id";
    if (!phoneRegex.test(member?.contact_no))
      newErrors.contact_no = "Please enter a valid 10-digit phone number";
    if (!phoneRegex.test(member?.emergency_no))
      newErrors.emergency_no = "Please enter a valid 10-digit phone number";
    if (!member?.address) newErrors.address = "Address is required";
    if (!member?.gender) newErrors.gender = "Select an Gender";
    if (!member?.blood_group) newErrors.blood_group = "Select an Blood Group";
    if (!member?.date_of_birth)
      newErrors.date_of_birth = "Date of Birth is required";
    if (!member?.occupation) newErrors.occupation = "Occupation is required";
    setErrors(newErrors);
    if (!member.status) {
      member.status = false;
    }
    if (Object.keys(newErrors).length === 0) {
      try {
        handleUpdate ? setHandleUpdate(false) : setHandleUpdate(true);
        dispatch(sendMembersData(member));
        setEditableFields(false);
        setEditcud({
          save: false,
          cancel: false,
          update: true,
        });
      } catch (error) {
        console.error("Error view enquiry: ", error.message);
      }
    }
  };

  const handleBacktoMember = () => {
    setIdentifyDialog(false);
    setDialogOpen(true);
  };

  return (
    <form>
      {openCapturePhotoDialog && (
        <CapturePhotoNonMobileDialog
          open={openCapturePhotoDialog}
          setOpen={setOpenCapturePhotoDialog}
          setAvatar={setAvatar}
        />
      )}
      <Grid
        container
        rowSpacing={4.5}
        columnSpacing={2.75}
        sx={{ background: "#fafafb" }}
      >
        <Grid
          container
          item
          xs={12}
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">Member Details</Typography>
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              mb: 2,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              "& button": { m: 1 },
            }}
          >
            <Avatar
              alt=""
              src={member?.avatar}
              sx={{ width: 120, height: 120, mb: 2 }}
            />
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                component="label"
                variant="contained"
                size="small"
                startIcon={<CloudUploadIcon />}
                disabled
              >
                Upload
                <VisuallyHiddenInput type="file" />
              </Button>
              <Button
                component="label"
                variant="contained"
                size="small"
                startIcon={<CameraIcon />}
                onClick={() => setOpenCapturePhotoDialog(true)}
                disabled={!editcud.save}
              >
                Click photo
              </Button>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8} lg={9}>
          <Paper>
            <TabContext value={tabsHeadValue}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                }}
              >
                <TabList
                  variant="scrollable"
                  scrollButtons="auto"
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{ overflow: "visible", width: "75%", display: "flex" }}
                >
                  <CustomTab label="Personal Details" value="1" />
                  <CustomTab label="Membership" value="2" />
                  <CustomTab label="Questionnaire" value="3" />
                </TabList>

                {member ? (
                  member.status !== undefined ? (
                    <Chip
                      className="chip"
                      label={`${member.status ? "Active" : "Inactive"}`}
                      color={member.status ? "success" : "error"}
                      sx={{
                        color: "white",
                        width: { xs: "18%", sm: "auto" },
                        marginLeft: { xs: "4%", sm: "10%" },
                        margin: "auto",
                        padding: { xs: "0%", sm: "10px" },
                      }}
                    />
                  ) : (
                    "Member exists, but status is not defined."
                  )
                ) : (
                  ""
                )}
              </Box>
              <TabPanel value="1">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack
                    spacing={2}
                    direction={{ xs: "column", sm: "row", md: "row" }}
                  >
                    <Button
                      id="Button_size"
                      variant="contained"
                      size="small"
                      disabled={!editcud.update}
                      startIcon={<EditIcon />}
                      onClick={handleUpdateClick}
                    >
                      Update Member Details
                    </Button>
                    {editcud.cancel ? (
                      <Button
                        variant="contained"
                        size="small"
                        startIcon={<CancelIcon />}
                        onClick={handleCancelClick}
                      >
                        {props.mode === "add" ? "Clear" : "Cancel"}
                      </Button>
                    ) : (
                      " "
                    )}
                    {isSmallScreen ? (
                      " "
                    ) : (
                      <Button
                        disabled={!editcud.save}
                        variant="contained"
                        size="small"
                        startIcon={<SaveIcon />}
                        onClick={handleSaveClick}
                      >
                        Save Details
                      </Button>
                    )}
                    {/* New Download Button */}

                    {identifyDialog ? (
                      <CustomDialog
                        open={dialogOpen}
                        handleClose={handleDialogClose}
                        onConfirm={handleConfirmForCancel}
                        content={"Do you want to Discard Changes ?"}
                      />
                    ) : (
                      <CustomDialog
                        open={dialogOpen}
                        handleClose={handleDialogClose}
                        onConfirm={handleConfirmForBacktoMember}
                        content={
                          "Some of the details are unsaved. Do you want to go back without saving ?"
                        }
                      />
                    )}
                    <Snackbar
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      open={openNotification}
                      autoHideDuration={6000}
                      onClose={handleNotificationClose}
                    >
                      <Alert
                        onClose={handleNotificationClose}
                        severity={notification.variant}
                        variant="filled"
                        sx={{ width: "100%" }}
                      >
                        {notification.message}
                      </Alert>
                    </Snackbar>
                  </Stack>
                  <Stack direction="row" sx={{ mt: { xs: "1rem", sm: "0" } }}>
                    <Button
                      id="Button_size"
                      variant="contained"
                      size="small"
                      startIcon={<PersonIcon />}
                      onClick={() =>
                        editcud.save === true
                          ? handleBacktoMember()
                          : navigate("/adminapp/members")
                      }
                    >
                      Back to Members
                    </Button>
                  </Stack>
                </Grid>
                <Divider sx={{ my: 3 }} />

                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      <ResponsiveTableRow
                        errorCellOne={errors.name}
                        errorCellTwo={errors.gender}
                        memberFieldCellOne={member?.name}
                        memberFieldCellTwo={member?.gender}
                        memberFieldCellTwoValue={member?.gender}
                        memberFieldCellTwoOptions={genders}
                        memberFieldCellOneType="text"
                        memberFieldCellTwoType="dropdown"
                        isSmallScreen={isSmallScreen}
                        fieldOneEditable={editableFields}
                        fieldTwoEditable={editableFields}
                        cellOneName="Name"
                        cellTwoName="Gender"
                        fieldOneName="name"
                        fieldTwoName="gender"
                        handleInputChange={handleInputChange}
                      />
                      <ResponsiveTableRow
                        errorCellOne={errors.contact_no}
                        errorCellTwo={errors.emergency_no}
                        memberFieldCellOne={member?.contact_no}
                        memberFieldCellTwo={member?.emergency_no}
                        memberFieldCellOneType="text"
                        memberFieldCellTwoType="text"
                        isSmallScreen={isSmallScreen}
                        fieldOneEditable={editableFields}
                        fieldTwoEditable={editableFields}
                        cellOneName="Contact Number"
                        cellTwoName="Emergency Number"
                        fieldOneName="contact_no"
                        fieldTwoName="emergency_no"
                        handleInputChange={handleInputChange}
                      />
                      <ResponsiveTableRow
                        errorCellOne={errors.email_id}
                        errorCellTwo={errors.address}
                        memberFieldCellOne={member?.email_id}
                        memberFieldCellTwo={member?.address}
                        memberFieldCellOneType="text"
                        memberFieldCellTwoType="text"
                        isSmallScreen={isSmallScreen}
                        fieldOneEditable={editableFields}
                        fieldTwoEditable={editableFields}
                        cellOneName="Email"
                        cellTwoName="Address"
                        fieldOneName="email_id"
                        fieldTwoName="address"
                        handleInputChange={handleInputChange}
                      />
                      <ResponsiveTableRow
                        errorCellOne={errors.blood_group}
                        errorCellTwo={errors.occupation}
                        memberFieldCellOne={member?.blood_group}
                        memberFieldCellTwo={member?.occupation}
                        memberFieldCellOneValue={member?.blood_group}
                        memberFieldCellOneOptions={bloodGroups}
                        memberFieldCellOneType="dropdown"
                        memberFieldCellTwoType="text"
                        isSmallScreen={isSmallScreen}
                        fieldOneEditable={editableFields}
                        fieldTwoEditable={editableFields}
                        cellOneName="Blood Group"
                        cellTwoName="Occupation"
                        handleInputChange={handleInputChange}
                        fieldOneName="blood_group"
                        fieldTwoName="occupation"
                      />
                      <ResponsiveTableRow
                        errorCellOne={errors.date_of_birth}
                        memberFieldCellOne={member?.date_of_birth}
                        memberFieldCellOneType="date"
                        isSmallScreen={isSmallScreen}
                        fieldOneEditable={editableFields}
                        fieldTwoEditable={editableFields}
                        cellOneName="Date of Birth"
                        fieldOneName="date_of_birth"
                        handleInputChange={handleInputChange}
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
                {isSmallScreen ? (
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    sx={{ mt: 3 }}
                  >
                    <Button
                      disabled={!editcud.save}
                      variant="contained"
                      size="small"
                      startIcon={<SaveIcon />}
                      onClick={handleSaveClick}
                    >
                      Save Details
                    </Button>
                  </Grid>
                ) : (
                  ""
                )}
              </TabPanel>

              <TabPanel value="2">
                {mode === "add" ? (
                  <MembershipDetails
                    memberId={member.id}
                    member={member}
                    onStatusUpdate={(status) =>
                      setMember((prev) => ({ ...prev, status }))
                    }
                    selectedMember={selectedMember}
                    membershipsData={membershipsData}
                  />
                ) : (
                  <MembershipDetails
                    memberId={member.id}
                    member={member}
                    onStatusUpdate={(status) =>
                      setMember((prev) => ({ ...prev, status }))
                    }
                    selectedMember={selectedMember}
                    membershipsData={membershipsData}
                  />
                )}
              </TabPanel>
              <TabPanel value="3">
                {mode === "add" ? (
                  <Questionnaire
                    mode="add"
                    screenSize={isSmallScreen}
                    memberId={queMemberId}
                  />
                ) : (
                  <Questionnaire screenSize={isSmallScreen} />
                )}
              </TabPanel>
              <TabPanel value="4">
                <Stack
                  spacing={2}
                  sx={{ direction: { xs: "column", sm: "row" } }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={handleUpdateClick}
                  >
                    Update Billing Details
                  </Button>
                  <Button
                    disabled={!editcud.save}
                    variant="contained"
                    size="small"
                    startIcon={<SaveIcon />}
                    onClick={handleSaveClick}
                  >
                    Save Details
                  </Button>
                </Stack>
                <Divider sx={{ my: 3 }} />
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          status
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            </TabContext>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};

export default ViewMemberDetails;
