import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../axiosApi/api";

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    const trimmedValue = inputValue.trim();
    setUsername(trimmedValue);
    setEmailError(!validateEmail(trimmedValue) || trimmedValue === "");
  };

  const handlePasswordBlur = () => {
    setPasswordError(password.trim() === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation for email and password
    if (!validateEmail(username) || password.trim() === "") {
      return;
    }

    try {
      const res = await login({
        username,
        password,
      });

      const { accessToken, refreshToken, expiresIn } = res;

      if (!accessToken || !refreshToken) {
        throw new Error("Invalid credentials");
      }

      // Save tokens and expiration time to localStorage
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("expiresIn", expiresIn);
      navigate("/adminapp");
    } catch (error) {
      console.error("Error during login:", error);
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <Box sx={{ minHeight: "100vh" }}>
        <Grid
          container
          direction="column"
          justifyContent="flex-end"
          sx={{
            minHeight: "100vh",
            backgroundImage: `url(enquery.jpg)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
          }}
        >
          <Grid item xs={12} sx={{ ml: 3, mt: 3 }}>
            <Box
              component="img"
              sx={{ height: "60px", position: "absolute" }}
              alt="Logo"
              src="capstone192.png"
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                minHeight: {
                  xs: "calc(100vh - 134px)",
                  md: "calc(100vh - 112px)",
                },
              }}
            >
              <Grid item>
                <Card
                  elevation={1}
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: 2,
                    boxShadow: 3,
                  }}
                >
                  <CardHeader title="Login" />

                  <CardContent>
                    <Box
                      sx={{
                        my: 8,
                        mx: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="form"
                        noValidate
                        onSubmit={handleSubmit}
                        sx={{ mt: 1 }}
                      >
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                          autoFocus
                          size="small"
                          value={username}
                          onChange={handleEmailChange}
                          onBlur={handleEmailChange}
                          error={emailError}
                          helperText={emailError ? "Invalid email address" : ""}
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          size="small"
                          name="password"
                          label="Password"
                          id="password"
                          autoComplete="current-password"
                          type={showPassword ? "text" : "password"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          onBlur={handlePasswordBlur}
                          error={passwordError}
                          helperText={
                            passwordError ? "Password cannot be empty" : ""
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox value="remember" color="primary" />
                          }
                          label="Remember me"
                        />
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Sign In
                        </Button>
                        <Grid container>
                          <Grid item xs>
                            <Link href="#" variant="body2">
                              Forgot password?
                            </Link>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000} // Set how long the expired message will be visible
              onClose={() => setSnackbarOpen(false)}
            >
              <Alert severity="error" sx={{ width: "100%" }}>
                Your session has been expired.
              </Alert>
            </Snackbar>
          </Grid>

          <Grid item xs={12} sx={{ m: 3, mt: 1, background: "#FFF" }}>
            <Container maxWidth="xl">
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={2}
                textAlign="center"
              >
                <Typography
                  variant="subtitle2"
                  color="secondary"
                  component="span"
                >
                  &copy; CAPSTONE&nbsp;
                  <Typography
                    component={Link}
                    variant="subtitle2"
                    href="https://capstone.com"
                    target="_blank"
                    underline="hover"
                  >
                    CAPSTONE
                  </Typography>
                </Typography>

                <Stack direction="row" spacing={1} textAlign="center">
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    component={Link}
                    href="#"
                    target="_blank"
                    underline="hover"
                  >
                    Terms
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color="secondary"
                    component={Link}
                    href="#"
                    target="_blank"
                    underline="hover"
                  >
                    Privacy
                  </Typography>
                </Stack>
              </Stack>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Login;
