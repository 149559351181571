import React from "react";

import { Box, Typography, Container, Paper } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import Banner from "../pageBanner/Banner";

const paperStyle = {
  p: 5,
  minWidth: "320px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flex: 3,
  maxWidth: "100%",
};

const Contact = () => {
  return (
    <>
      <Banner
        backgroundImage="/contactBg.jpg"
        title="Contact Us"
        subtitle="Feel free to drop me an email or call, it would be great to hear from you!"
        overlayColor="rgba(11, 75, 84, 0.4)"
      />
      <Container>
        <Box sx={{ mt: 7, mb: 12 }}>
          <Typography variant="h3" gutterBottom align="center">
            Come Visit Us Anytime
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <Paper elevation={0} sx={paperStyle}>
              <Typography component="p">
                <PlaceIcon sx={{ fontSize: "4rem" }} />
              </Typography>
              <Typography variant="h6">Contact address</Typography>
            </Paper>
            <Paper elevation={0} sx={paperStyle}>
              <Typography component="p">
                <CallIcon sx={{ fontSize: "4rem" }} />
              </Typography>
              <Typography variant="h6">9637666990</Typography>
            </Paper>
            <Paper elevation={0} sx={paperStyle}>
              <Typography component="p">
                <EmailIcon sx={{ fontSize: "4rem" }} />
              </Typography>
              <Typography variant="h6">info@capstone.com</Typography>
            </Paper>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Contact;
