import React from "react";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

function Copyright() {
  return (
    <React.Fragment>
      {"© "} {new Date().getFullYear()}{" "}
      <Link color="inherit" href="https://capstonegym.com/">
        CAPSTONE GYM.
      </Link>{" "}
      All rights reserved.
    </React.Fragment>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: "flex",
  borderRadius: 50,
  color: "#fff",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "secondary.main",
  mr: 1,
  "&:hover": {
    bgcolor: "secondary.dark",
  },
};

const Footer = () => {
  return (
    <Typography
      component="footer"
      sx={{ display: "flex", bgcolor: "primary.main" }}
    >
      <Container sx={{ my: 8, display: "flex" }}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={6} md={6}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              spacing={2}
              sx={{ height: 120 }}
            >
              <Grid item sx={{ display: "flex" }}>
                <Box
                  component="a"
                  href="https://www.facebook.com/"
                  sx={iconStyle}
                >
                  <FacebookIcon />
                </Box>
                <Box component="a" href="https://twitter.com/" sx={iconStyle}>
                  <TwitterIcon />
                </Box>
                <Box
                  component="a"
                  href="https://www.instagram.com/"
                  sx={iconStyle}
                >
                  <InstagramIcon />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Typography variant="h6" marked="left" gutterBottom>
              Legal
            </Typography>
            <Box component="ul" sx={{ m: 0, listStyle: "none", p: 0 }}>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link sx={{ color: "text.primary" }} href="/terms">
                  Terms
                </Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link sx={{ color: "text.primary" }} href="/privacy">
                  Privacy
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={2}>
            <Typography variant="h6" marked="left" gutterBottom>
              Language
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Copyright />
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
};

export default Footer;
