import React from "react";
import { styled } from "@mui/system";
import "./videoBox.css";
import ReactPlayer from "react-player";
import { Box, Grid, Typography } from "@mui/material";

const Div = styled("div")(({ theme }) => ({
  color: "#FFF",
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(1),
}));

const VideoBox = () => {
  return (
    <Div
      className="waveDivider"
      style={{
        backgroundColor: "#333",
        padding: "50px 20px",
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6}>
          <div className="video-player-wrapper">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=HQfF5XRVXjU"
              controls
              width="100%"
              height="50vh"
              volume={0.4}
              config={{
                youtube: {
                  playerVars: { showinfo: 1 },
                },
              }}
            />
          </div>
          <Box sx={{ width: "100%", textAlign: "center", paddingTop: "20px" }}>
            <Typography variant="h3" sx={{ mt: 5, mb: 3 }}>
              Main Heading
            </Typography>
            <Typography variant="h6" gutterBottom>
              Sub heading copy here
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Div>
  );
};

export default VideoBox;
