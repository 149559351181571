import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  Typography,
  Stack,
  Avatar,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AnalyticCard from "../cards/AnalyticCard";
import MainCard from "../cards/MainCard";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTrainersData,
  selectTrainersData,
  selectTrainerStatus,
  setTrainersData,
} from "../../../../slices/trainersSlice";
import { useNavigate } from "react-router-dom";
import { useRadioGroup } from "@mui/material/RadioGroup";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();
  let checked = false;
  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <FormControlLabel checked={checked} {...props} />;
}

const Trainers = () => {
  const [selectedTime, setSelectedTime] = useState("All");

  const bufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer.data);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const trainersColumns = [
    {
      field: "id",
      headerName: "ID",
      width: isSmallScreen ? 100 : 70,
      minWidth: 80,
    },
    {
      field: "avatar",
      headerName: "Avatar",
      width: isSmallScreen ? 130 : 80,
      minWidth: 90,
      renderCell: (params) => {
        console.log("params : ", params);
        return <Avatar alt="" src="/path/to/profile-picture.jpg" />;
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: isSmallScreen ? 130 : 150,
      minWidth: 150,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: "contact_no",
      headerName: "Contact No",
      width: isSmallScreen ? 140 : 150, 
      minWidth: 150,
    },
    {
      field: "emergency_no",
      headerName: "Emergency No",
      width: isSmallScreen ? 170 : 150, 
      minWidth: 150,
    },
    {
      field: "email_id",
      headerName: "Email ID",
      width: isSmallScreen ? 200 : 150, 
      minWidth: 150,
    },
    {
      field: "timing",
      headerName: "Time",
      width: isSmallScreen ? 180 : 150, 
      minWidth: 150,
    },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const trainersData = useSelector(selectTrainersData);
  console.log("trainers info: ", trainersData);
  const trainerStatus = useSelector(selectTrainerStatus);
  const trainercount = trainersData.filter(
    (time) => time.timing === selectedTime
  ).length;

  useEffect(() => {
    if (trainerStatus === "idle") {
      dispatch(fetchTrainersData());
    } else {
      dispatch(setTrainersData(trainersData));
    }
  }, [dispatch, trainerStatus, setTrainersData]);

  const handleRowClick = (params, event, details) => {
    const selectedTrainerId = params.row.id;
    navigate(`/adminapp/trainerDetails/${selectedTrainerId}`);
  };

  const addNewTrainer = () => {
    navigate(`/adminapp/trainerDetails/`);
  };

  const handleRadio = (event) => {
    setSelectedTime(event.target.value);
  };

  const handleRows = (time) => {
    return time === "All"
      ? trainersData
      : trainersData.filter((trainer) => trainer.timing === time);
  };

  return (
    <Grid
      container
      rowSpacing={4.5}
      columnSpacing={2.75}
      sx={{ background: "#fafafb" }}
    >
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5">Trainers</Typography>
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Button
            variant="contained"
            startIcon={<PersonAddIcon />}
            onClick={() => addNewTrainer()}
          >
            New Trainer
          </Button>
        </ButtonGroup>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticCard
          title="Total Trainers"
          count={trainersData.length}
          extra="5"
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        {selectedTime === "All" ? (
          " "
        ) : (
          <AnalyticCard
            title={"Trainers At " + selectedTime + " Time"}
            count={trainercount}
            extra="5"
          />
        )}
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">Trainer Details</Typography>
            <RadioGroup
              name="use-radio-group"
              defaultValue="All"
              onChange={handleRadio}
            >
              <Stack direction="rwo">
                <MyFormControlLabel
                  value="Morning"
                  label="Morning"
                  control={<Radio />}
                />
                <MyFormControlLabel
                  value="Evening"
                  label="Evening"
                  control={<Radio />}
                />
                <MyFormControlLabel
                  value="All"
                  label="All"
                  control={<Radio />}
                />
              </Stack>
            </RadioGroup>
          </Grid>
          <Grid item />
        </Grid>

        <MainCard sx={{ mt: 2 }} content={false}>
          <Box sx={{ height: 400, width: 1 }}>
            <DataGrid
              columns={trainersColumns}
              rows={handleRows(selectedTime)}
              pageSize={50}
              onRowClick={handleRowClick}
              initialState={{
                sorting: {
                  sortModel: [{ field: "id", sort: "desc" }],
                },
              }}
            />
          </Box>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default Trainers;
