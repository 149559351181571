import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import Banner from "../../pageBanner/Banner";

const SelfTrainingProgramme = () => {
  return (
    <>
      {/* Page Banner ----------------------------------------------------------- */}
      <Banner
        backgroundImage="/selfTraining.jpg"
        title="Self Training Program"
        subtitle="Empower Yourself with Self-Guided Workouts"
        overlayColor="rgba(11, 75, 84, 0.4)"
      />
      {/* Desktop Page -------------------------------------------------------------------- */}
      <Box sx={{ display: { xs: "none", sm: "none", md: "block", lg: "block", xl: "block" } }}>
        <Grid container spacing={0} sx={{ background: "#fffdee" }}>
          <Grid item xs={12} xl={1}></Grid>
          <Grid item xs={12} xl={10}>
            <Box sx={{ minHeight: "80%" }}>
              <Grid container spacing={0}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ width: "80%", margin: "10%" }}>
                    <Typography variant="h5" mb={2}>
                      Build Real-World Strength!
                    </Typography>
                    <Typography>
                      With our innovative group fitness training program, you can gain the
                      functional fitness that will help you in your everyday life. From running
                      errands to playing sports with friends, our exercises are designed to
                      increase physical strength, flexibility, mobility and more! Our
                      experienced trainers have workouts suited for all levels so everyone can
                      get the most out of their time while benefiting from better overall
                      health.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "400px",
                      backgroundImage: `url('/gallery/image04.jpg')`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  ></Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "400px",
                      backgroundImage: `url('/gallery/image04.jpg')`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  ></Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ width: "80%", margin: "10%" }}>
                    <Typography variant="h5" mb={2}>
                      Unlock Your Potential!
                    </Typography>
                    <Typography>
                      Nothing beats having a group of individuals to help you stay motivated
                      along your fitness journey. Imagine working out with friends who
                      understand what it takes to reach your goals. With group fitness training,
                      friendly support will always be there for you – striving together makes
                      the whole process more enjoyable! Not only that, but push-yourself
                      encouragement from fellow members also helps keep you on track so that
                      every step closer to success is celebrated collectively.
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box sx={{ width: "80%", margin: "10%" }}>
                    <Typography variant="h5" mb={2}>
                      Achieve Results Fast!
                    </Typography>
                    <Typography>
                      Our group fitness training program keeps you accountable so you’ll be able
                      to achieve the results that you want fast! Unlike working out on your own,
                      joining this program ensures that a certified professional will hold you
                      accountable each step of the way. From tracking your workouts to setting
                      goals, they give individuals the tools they need to become empowered and
                      reach their desired outcome quickly.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "400px",
                      backgroundImage: `url('/gallery/image04.jpg')`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  ></Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} xl={1}></Grid>
        </Grid>
      </Box>

      {/* Mobile Page -------------------------------------------------------------------- */}
      <Box
        sx={{
          display: { xs: "block", sm: "block", md: "none", lg: "none", xl: "none" },
          position: "relative",
          backgroundImage: `url('/gallery/bannerBg.jpg')`,
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          minHeight: "400px",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                padding: "10%",
              }}
            >
              <Typography variant="h5" mb={2}>
                Build Real-World Strength!
              </Typography>
              <Typography>
                With our innovative group fitness training program, you can gain the functional
                fitness that will help you in your everyday life. From running errands to
                playing sports with friends, our exercises are designed to increase physical
                strength, flexibility, mobility and more! Our experienced trainers have workouts
                suited for all levels so everyone can get the most out of their time while
                benefiting from better overall health.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                padding: "10%",
              }}
            >
              <Typography variant="h5" mb={2}>
                Unlock Your Potential!
              </Typography>
              <Typography>
                Nothing beats having a group of individuals to help you stay motivated along
                your fitness journey. Imagine working out with friends who understand what it
                takes to reach your goals. With group fitness training, friendly support will
                always be there for you – striving together makes the whole process more
                enjoyable! Not only that, but push-yourself encouragement from fellow members
                also helps keep you on track so that every step closer to success is celebrated
                collectively.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                padding: "10%",
              }}
            >
              <Typography variant="h5" mb={2}>
                Achieve Results Fast!
              </Typography>
              <Typography>
                Our group fitness training program keeps you accountable so you’ll be able to
                achieve the results that you want fast! Unlike working out on your own, joining
                this program ensures that a certified professional will hold you accountable
                each step of the way. From tracking your workouts to setting goals, they give
                individuals the tools they need to become empowered and reach their desired
                outcome quickly.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SelfTrainingProgramme;
