import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMembershipById,
  selectMembershipsData,
  flagReset,
  sendMembershipData,
  sendMembershipDataRes,
  fetchMembershipsData,
} from "../../../../slices/membershipsSlice";
import {
  Box,
  Grid,
  Typography,
  Button,
  Stack,
  Paper,
  Snackbar,
  Alert,
  TableContainer,
  Table,
  TableBody,
  Chip,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import CustomDialog from "../../../common/CustomDialog";
import ResponsiveTableRow from "../../../common/ResponsiveTableRow";
import styled from "@emotion/styled";
import "./ViewMembershipDetails.css";

export default function ViewMembershipDetails({ mode }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [membership, setMembership] = useState({
    name: "",
    fee: "",
    status: false,
    term_months: "",
    start_date: "",
  });
  const [editCud, setEditCud] = useState({
    save: false,
    cancel: false,
    update: true,
  });
  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState({});
  const [open, setOpen] = useState(false);
  const [editableFields, setEditableFields] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [identifyDialog, setIdentifyDialog] = useState(false);

  const selectedMembership = useSelector((state) =>
    selectMembershipById(state, id)
  );
  const membershipsData = useSelector(selectMembershipsData);
  const sendMembershipRes = useSelector(sendMembershipDataRes);

  useEffect(() => {
    if (mode === "add") {
      setEditableFields(true);
      setEditCud({ save: true, cancel: true, update: false });
    } else {
      const membershipToEdit = membershipsData.find(
        (membership) => membership.id === Number(id)
      );
      setMembership(membershipToEdit || {});
    }
  }, [mode, membershipsData, id]);

  useEffect(() => {
    if (sendMembershipRes === 200) {
      setOpen(true);
      setNotification({
        variant: "success",
        message: "Membership details submitted successfully.",
      });
      dispatch(flagReset());
      dispatch(fetchMembershipsData());
      navigate("/adminapp/memberships");
    } else if (sendMembershipRes === 208) {
      setOpen(true);
      setNotification({
        variant: "error",
        message: "Membership details already exist.",
      });
      setEditableFields(true);
      setEditCud({ save: true, cancel: true, update: true });
      dispatch(flagReset());
    }
  }, [sendMembershipRes, dispatch, navigate]);

  const handleInputChange = (field, value) => {
    if (errors[field]) {
      const updatedErrors = { ...errors };
      delete updatedErrors[field];
      setErrors(updatedErrors);
    }
    setMembership((prevMembership) => ({
      ...prevMembership,
      [field]: value,
    }));
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!membership.name) newErrors.name = "Please enter Name";
    if (!membership.term_months)
      newErrors.term_months = "Please enter Membership term";
    if (!membership.fee) newErrors.fee = "Please enter Fee";
    if (!membership.start_date) newErrors.start_date = "Select a Start Date";

    if (Object.keys(newErrors).length === 0) {
      const updatedMembership = { ...membership, status: true };
      dispatch(sendMembershipData(updatedMembership));
    } else {
      setErrors(newErrors);
    }
  };

  const handleCancelClick = () => {
    setDialogOpen(true);
    setIdentifyDialog(true);
  };

  const handleUpdateClick = () => {
    setEditableFields(true);
    setEditCud({ save: true, cancel: true, update: false });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmForCancel = () => {
    if (mode === "add") {
      setMembership({
        name: "",
        fee: "",
        term_months: "",
        status: false,
        start_date: "",
      });
      setEditableFields(true);
      setEditCud({ save: true, cancel: true, update: false });
      setErrors({});
    } else {
      setMembership(selectedMembership);
      setEditableFields(false);
      setEditCud({ save: false, cancel: false, update: true });
    }
    setDialogOpen(false);
  };

  const handleConfirmForBacktoMembership = () => {
    navigate("/adminapp/memberships");
  };

  const CustomTab = styled(Tab)(({ theme }) => ({
    position: "relative",
    "&.Mui-selected": {
      backgroundColor: "#242105",
      color: "#ffeb3b",
      "&::before": {
        content: '""',
        position: "absolute",
        top: "90%",
        left: "50%",
        transform: "translateX(-50%)",
        width: 0,
        height: 0,
        borderTop: `8px solid ${theme.palette.primary.main}`,
        borderLeft: "8px solid transparent",
        borderRight: "8px solid transparent",
        marginTop: -4,
        zIndex: 1,
      },
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <form>
      <Grid
        container
        rowSpacing={4.5}
        columnSpacing={2.75}
        sx={{ background: "#fafafb" }}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5">Membership Details</Typography>
        </Grid>

        <Grid item xs={12} md={8} lg={9} sx={{ margin: "auto" }}>
          <Paper>
            <TabContext value="1">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList>
                  <CustomTab label="Membership Details" value="1" />

                  {membership ? (
                    membership.status !== undefined ? (
                      <Chip
                        label={`${membership.status ? "Active" : "Inactive"}`}
                        color={membership.status ? "success" : "error"}
                        sx={{
                          color: "white",
                          marginLeft: "60%",
                          padding: "10px",
                          display: "center",
                        }}
                      />
                    ) : (
                      "Member exists, but status is not defined."
                    )
                  ) : (
                    ""
                  )}
                </TabList>
              </Box>
              <TabPanel value="1">
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <Button id="Button_back"
                      variant="contained"
                      size="small"
                      startIcon={<EditIcon />}
                      disabled={!editCud.update}
                      onClick={handleUpdateClick}
                    >
                      Update Membership Details
                    </Button>
                    {editCud.cancel && (
                      <Button id="Button_back"
                        variant="contained"
                        size="small"
                        startIcon={<CancelIcon />}
                        onClick={handleCancelClick}
                      >
                        {mode === "add" ? "Clear" : "Cancel"}
                      </Button>
                    )}
                    <Button id="Button_back"
                      variant="contained"
                      size="small"
                      startIcon={<SaveIcon />}
                      disabled={!editCud.save}
                      onClick={handleSaveClick}
                    >
                      Save Membership
                    </Button>
                  </Stack>
                  <Button id="Button_back"
                    variant="contained"
                    size="small"
                    startIcon={<ContactMailIcon />}
                    onClick={handleConfirmForBacktoMembership}
                  >
                    Back to Memberships
                  </Button>

                  <CustomDialog
                    open={dialogOpen}
                    handleClose={handleDialogClose}
                    onConfirm={
                      identifyDialog
                        ? handleConfirmForCancel
                        : handleConfirmForBacktoMembership
                    }
                    content={
                      identifyDialog
                        ? "Do you want to discard changes?"
                        : "Go back without saving?"
                    }
                  />

                  <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert
                      onClose={handleClose}
                      severity={notification.variant}
                      variant="filled"
                    >
                      {notification.message}
                    </Alert>
                  </Snackbar>
                </Grid>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <ResponsiveTableRow
                        errorCellOne={errors.name}
                        errorCellTwo={errors.term_months}
                        memberFieldCellOne={membership?.name}
                        memberFieldCellTwo={membership?.term_months}
                        memberFieldCellOneType="text"
                        memberFieldCellTwoType="text"
                        fieldOneEditable={editableFields}
                        fieldTwoEditable={editableFields}
                        isSmallScreen={isSmallScreen}
                        cellOneName="Name"
                        cellTwoName="Membership Term"
                        fieldOneName="name"
                        fieldTwoName="term_months"
                        handleInputChange={handleInputChange}
                      />
                      <ResponsiveTableRow
                        errorCellOne={errors.start_date}
                        errorCellTwo={errors.fee}
                        memberFieldCellOne={membership?.start_date}
                        memberFieldCellTwo={membership?.fee}
                        memberFieldCellOneType="date"
                        memberFieldCellTwoType="text"
                        fieldOneEditable={editableFields}
                        fieldTwoEditable={editableFields}
                        isSmallScreen={isSmallScreen}
                        cellOneName="Start Date"
                        cellTwoName="Fee"
                        fieldOneName="start_date"
                        fieldTwoName="fee"
                        handleInputChange={handleInputChange}
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            </TabContext>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
