import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";

const Banner = ({
  backgroundImage,
  title,
  subtitle,
  buttonText,
  showBackdrop,
  overlayColor,
  linkUrl,
}) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "450px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        position: "relative",
      }}
    >
      {showBackdrop && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: overlayColor,
          }}
        ></div>
      )}
      <Container sx={{ zIndex: 1 }}>
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h5">{subtitle}</Typography>
        {buttonText && (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ marginTop: 2 }}
            href={linkUrl}
          >
            {buttonText}
          </Button>
        )}
      </Container>
    </Box>
  );
};

// Default props
Banner.defaultProps = {
  showBackdrop: true,
  overlayColor: "rgba(0, 0, 0, 0.5)",
  buttonText: null,
  linkUrl: null,
};

export default Banner;
