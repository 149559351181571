import React from "react";
import AppNavBar from "../navigation/AppNavBar";

const Headers = () => {
  return (
    <>
      <AppNavBar />
    </>
  );
};

export default Headers;
