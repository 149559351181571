import React from "react";

import { Box, Button, Grid, Typography } from "@mui/material";
import Banner from "../../pageBanner/Banner";

const PersonalTrainingProgramme = () => {
  return (
    <>
      <Banner
        backgroundImage="/personalTrainer.jpg"
        title="Personal Fitness Trainer Program"
        subtitle="Transform Your Life with Expert Guidance and Support"
        overlayColor="rgba(11, 75, 84, 0.4)"
      />
      <Grid container spacing={0} sx={{ background: "#fffdee" }}>
        <Grid item xs={12} lg={1}></Grid>
        <Grid item xs={12} lg={10}>
          <Box sx={{ minHeight: "100vh" }}>
            <Grid container spacing={0} sx={{ background: "#ccc" }}>
              <Grid item xs={12} md={6}>
                <Box sx={{ width: "80%", margin: "10%" }}>
                  <Typography variant="h3" mb={2}>
                    Personal Training Programme
                  </Typography>
                  <Typography>
                    Personal training is there to help you achieve certain
                    fitness goals, including but not limited to weight loss,
                    strength, toning, or overall health management. Since each
                    of us has an extremely different fitness level than personal
                    training requires an individual approach.
                  </Typography>
                  <Button variant="contained" sx={{ mt: "40px" }}>
                    Get in touch &#62;
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    width: "80%",
                    margin: "10%",
                    height: "300px",
                    backgroundImage: `url('/selfTraining.jpg')`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ background: "#818286" }}>
              <Grid item xs={12} md={6}>
                <Box sx={{ width: "80%", margin: "10%" }}>
                  <Typography variant="h3" mb={2}>
                    Group Training Programme
                  </Typography>
                  <Typography>
                    Our group fitness training program keeps you accountable so
                    you’ll be able to achieve the results that you want fast!
                    Unlike working out on your own, joining this program ensures
                    that a certified professional will hold you accountable each
                    step of the way. From tracking your workouts to setting
                    goals, they give individuals the tools they need to become
                    empowered and reach their desired outcome quickly.
                  </Typography>
                  <Button variant="contained" sx={{ mt: "40px" }}>
                    Get in touch &#62;
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    width: "80%",
                    margin: "10%",
                    height: "300px",
                    backgroundImage: `url('/selfTraining.jpg')`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ background: "#ccc" }}>
              <Grid item xs={12} md={6}>
                <Box sx={{ width: "80%", margin: "10%" }}>
                  <Typography variant="h3" mb={2}>
                    Nutrition Programme
                  </Typography>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat
                    incididunt ut laboret.
                  </Typography>
                  <Button variant="contained" sx={{ mt: "40px" }}>
                    Get in touch &#62;
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    width: "80%",
                    margin: "10%",
                    height: "300px",
                    backgroundImage: `url('/selfTraining.jpg')`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} lg={1}></Grid>
      </Grid>
    </>
  );
};

export default PersonalTrainingProgramme;
