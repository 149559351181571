import React, { useEffect } from "react";
import CustomCarousel from "../carousel/CustomCarousel";
import GalleryCarousel from "../carousel/GalleryCarousel";
import Testimonials from "../testimonial/Testimonials";
import VideoBox from "../videoBox/VideoBox";
import QuickEnquiry from "../quickEnquiry/QuickEnquiry";

import { useDispatch } from "react-redux";
import { resetFocus, setFocusOnInputField } from "../../slices/focusInputSlice";

export default function Home() {
  const dispatch = useDispatch();

  const scrollFocusHandler = () => {
    if (window.location.pathname === "/enquiry") {
      const element = document.getElementById("enquiry");
      if (element) {
        element.scrollIntoView();
        console.log("dispatch focus action........");
        dispatch(setFocusOnInputField("name"));
      }
    }
  };

  useEffect(() => {
    scrollFocusHandler();
    dispatch(resetFocus());
  }, []);

  return (
    <>
      <CustomCarousel />
      <GalleryCarousel />
      <Testimonials />
      <VideoBox />
      <QuickEnquiry />
    </>
  );
}
