import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTrainerById,
  fetchTrainersData,
  selectTrainersData,
  sendTrainersData,
  sendTrainerDataRes,
} from "../../../../slices/trainersSlice";

import {
  Box,
  Grid,
  Typography,
  Button,
  Avatar,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Snackbar,
  Alert,
} from "@mui/material";
import CustomDialog from "../../../common/CustomDialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ResponsiveTableRow from "../../../common/ResponsiveTableRow";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import CancelIcon from "@mui/icons-material/Cancel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styled from "@emotion/styled";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import SaveIcon from "@mui/icons-material/Save";
import { genders } from "../members/memberConstant";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { flagReset } from "../../../../slices/trainersSlice";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "unset",
  },
  "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "0px",
  },
}));

const ViewTrainerDetails = (props) => {
  const bloodGroups = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];
  const times = ["Morning", "Evening", "Morning & Evening"];
  const [selectProfile, setSelectProfile] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const { mode } = props;
  const dispatch = useDispatch();
  const selectedTrainer = useSelector((state) => selectTrainerById(state, id));
  const trainerData = useSelector(selectTrainersData);
  const base64String = btoa(String.fromCharCode.apply(null, trainerData?.data));
  const imageUrl = `data:image/jpeg;base64,${base64String}`;
  const dateOfBirth = selectedTrainer?.date_of_birth
    ? dayjs(selectedTrainer?.date_of_birth)
    : "";

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const sendtrainerres = useSelector(sendTrainerDataRes);
  const [openNotification, setOpenNotification] = useState(false);
  const [trainer, setTrainer] = useState({
    name: "",
    gender: "",
    contact_no: "",
    email_id: "",
    address: "",
    blood_group: "",
    date_of_birth: "",
    emergency_no: "",
    profilePhoto: " ",
    timing: "",
  });

  const handleNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNotification(false);
  };
  const [notification, setNotification] = useState({});
  const [identifyDialog, setIdentifyDialog] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleConfirmForCancel = () => {
    if (mode === "add") {
      setTrainer({
        name: "",
        gender: "",
        contact_no: "",
        email_id: "",
        address: "",
        blood_group: "",
        date_of_birth: "",
        emergency_no: "",
        profilePhoto: "",
        timing: "",
      });
      setErrors({});
      setEditableFields(true);
      setEditcud({
        save: true,
        cancel: true,
        update: false,
      });
    } else {
      setTrainer(selectedTrainer);
      setEditableFields(false);
      setEditcud({
        save: false,
        cancel: false,
        update: true,
      });
    }
    setDialogOpen(false);
  };

  const handleConfirmForBacktoTrainer = () => {
    setTrainer(selectedTrainer);
    navigate("/adminapp/trainers");
  };

  const [errors, setErrors] = useState({
    name: "",
    email_id: "",
    contact_no: "",
    date_of_birth: "",
    address: "",
    gender: "",
    timing: "",
    blood_group: "",
    emergency_no: "",
  });
  const [tabsHeadValue, setTabsValueHead] = useState("1");

  const [editableFields, setEditableFields] = useState(false);

  const [editcud, setEditcud] = useState({
    save: false,
    cancel: false,
    update: true,
  });

  useEffect(() => {
    dispatch(fetchTrainersData());
  }, [dispatch, id]);

  useEffect(() => {
    if (mode === "add") {
      setEditableFields(true);
      setEditcud({
        save: true,
        cancel: true,
        update: false,
      });
    } else {
      setTrainer(trainerData.find((trainer) => trainer.id === Number(id)));
    }
  }, [trainerData]);

  useEffect(() => {
    console.log("trainer reponse ", sendtrainerres);
    if (sendtrainerres === 200) {
      console.log("entered in useffect status 200");
      setOpenNotification(true);
      setNotification({
        variant: "success",
        message: "Trainer Details Submitted Successfully.",
      });
      dispatch(flagReset("0"));
      dispatch(fetchTrainersData());
      if (trainer.id === undefined) {
        navigate("/adminapp/trainers");
      }
    } else if (sendtrainerres === 208) {
      setOpenNotification(true);
      setNotification({
        variant: "error",
        message: "Trainer Details Already Existed.",
      });
      setEditableFields(true);
      setEditcud({
        save: true,
        cancel: true,
        update: true,
      });
      dispatch(flagReset("0"));
    }
  }, [sendtrainerres, selectedTrainer]);

  const handleChange = (event, newValue) => {
    setTabsValueHead(newValue);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const CustomTab = styled(Tab)(({ theme }) => ({
    position: "relative",
    overflow: "visible",
    "&.Mui-selected": {
      overflow: "visible",
      backgroundColor: "#242105",
      color: "#ffeb3b",
      "&::before": {
        content: '""',
        position: "absolute",
        top: "90%",
        left: "50%",
        transform: "translateX(-50%)",
        width: 0,
        height: 0,
        borderTop: `8px solid ${theme.palette.primary.main}`,
        borderLeft: "8px solid transparent",
        borderRight: "8px solid transparent",
        marginTop: -4,
        zIndex: 1,
        overflow: "visible",
      },
    },
  }));

  const commonStyleForInput = {
    label: {
      shrink: true,
      style: { fontWeight: "bold", textTransform: "uppercase", color: "" },
    },
    input: {
      style: { color: "#242105" },
    },
  };

  const handleUpdateClick = () => {
    setEditableFields(true);
    setEditcud({
      save: true,
      cancel: true,
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectProfile(URL.createObjectURL(file));
  };

  const handleInputChange = (field, value) => {
    if (errors[field]) {
      const removeError = Object.fromEntries(
        Object.entries(errors).filter(([k]) => k !== field)
      );
      console.log("after removed Error", removeError);
      setErrors(removeError);
    }

    if (field === "contact_no" || field === "emergency_no") {
      const numericValue = value.replace(/[^0-9]/g, "");
      if (numericValue.length <= 10) {
        const updatedTrainer = {
          ...trainer,
          [field]: numericValue,
        };
        setTrainer(updatedTrainer);
      }
    } else {
      const updatedTrainer = {
        ...trainer,
        [field]: value,
      };
      setTrainer(updatedTrainer);
    }
  };

  const handleCancelClick = () => {
    setDialogOpen(true);
    setIdentifyDialog(true);
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    // validation
    const phoneRegex = /^[0-9]{10}$/;
    const newErrors = {};
    if (!trainer?.name) newErrors.name = "Name is required";
    if (!trainer?.email_id) newErrors.email_id = "Email is required";
    if (!phoneRegex.test(trainer?.contact_no))
      newErrors.contact_no = "Please enter a valid 10-digit phone number";
    if (!phoneRegex.test(trainer?.emergency_no))
      newErrors.emergency_no = "Please enter a valid 10-digit phone number";
    if (!trainer?.address) newErrors.address = "Address is required";
    if (!trainer?.gender) newErrors.gender = "Select an Gender";
    if (!trainer?.timing) newErrors.timing = "Select an Time";
    if (!trainer?.blood_group) newErrors.blood_group = "Select an Blood Group";

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      try {
        dispatch(sendTrainersData(trainer));
        setEditableFields(false);
        setEditcud({
          save: false,
          cancel: false,
          update: true,
        });
      } catch (error) {
        console.error("Error view enquiry: ", error.message);
      }
    }
  };

  const handleBacktoTrainer = () => {
    setIdentifyDialog(false);
    setDialogOpen(true);
  };

  return (
    <form>
      <Grid
        container
        rowSpacing={4.5}
        columnSpacing={2.75}
        sx={{ background: "#fafafb" }}
      >
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">Trainers Details</Typography>
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              mb: 2,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              "& button": { m: 1 },
            }}
          >
            <Avatar
              alt=""
              src={selectProfile || imageUrl}
              sx={{ width: 120, height: 120, mb: 2 }}
            />
            <Button
              component="label"
              variant="contained"
              size="small"
              startIcon={<CloudUploadIcon />}
              disabled={!editableFields}
            >
              Select Profile Photo
              <VisuallyHiddenInput type="file" onChange={handleFileChange} />
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8} lg={9}>
          <Paper>
            <TabContext value={tabsHeadValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{ overflow: "visible" }}
                >
                  <CustomTab label="Trainer Details" value="1" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack
                    spacing={2}
                    direction={{ xs: "column", sm: "row", md: "row" }}
                  >
                    <Button
                      id="Button_size"
                      variant="contained"
                      size="small"
                      disabled={!editcud.update}
                      startIcon={<EditIcon />}
                      onClick={handleUpdateClick}
                    >
                      Update Trainers Details
                    </Button>
                    {editcud.cancel ? (
                      <Button
                        variant="contained"
                        size="small"
                        startIcon={<CancelIcon />}
                        onClick={handleCancelClick}
                      >
                        {props.mode === "add" ? "Clear" : "Cancel"}
                      </Button>
                    ) : (
                      " "
                    )}
                    {isSmallScreen ? (
                      " "
                    ) : (
                      <Button
                        disabled={!editcud.save}
                        variant="contained"
                        size="small"
                        startIcon={<SaveIcon />}
                        onClick={handleSaveClick}
                      >
                        Save Details
                      </Button>
                    )}
                    {identifyDialog ? (
                      <CustomDialog
                        open={dialogOpen}
                        handleClose={handleDialogClose}
                        onConfirm={handleConfirmForCancel}
                        content={"Do you want to Discard Changes ?"}
                      />
                    ) : (
                      <CustomDialog
                        open={dialogOpen}
                        handleClose={handleDialogClose}
                        onConfirm={handleConfirmForBacktoTrainer}
                        content={
                          "Some of the details are unsaved. Do you want to go back without saving ?"
                        }
                      />
                    )}
                    <Snackbar
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      open={openNotification}
                      autoHideDuration={6000}
                      onClose={handleNotificationClose}
                    >
                      <Alert
                        onClose={handleNotificationClose}
                        severity={notification.variant}
                        variant="filled"
                        sx={{ width: "100%" }}
                      >
                        {notification.message}
                      </Alert>
                    </Snackbar>
                  </Stack>
                  <Stack direction="row" sx={{ mt: { xs: "1rem", sm: "0" } }}>
                    <Button
                      id="Button_size"
                      variant="contained"
                      size="small"
                      startIcon={<PersonIcon />}
                      onClick={() =>
                        editcud.save === true
                          ? handleBacktoTrainer()
                          : navigate("/adminapp/trainers")
                      }
                    >
                      Back to Trainers
                    </Button>
                  </Stack>
                </Grid>
                <Divider sx={{ my: 3 }} />

                <TableContainer>
                  <Table aria-label="simple table">
                    <TableBody>
                      <ResponsiveTableRow
                        errorCellOne={errors.name}
                        errorCellTwo={errors.gender}
                        memberFieldCellOne={trainer?.name}
                        memberFieldCellTwo={trainer?.gender}
                        memberFieldCellTwoValue={trainer?.gender}
                        memberFieldCellTwoOptions={genders}
                        memberFieldCellOneType="text"
                        memberFieldCellTwoType="dropdown"
                        isSmallScreen={isSmallScreen}
                        fieldOneEditable={editableFields}
                        fieldTwoEditable={editableFields}
                        cellOneName="Name"
                        cellTwoName="Gender"
                        fieldOneName="name"
                        fieldTwoName="gender"
                        handleInputChange={handleInputChange}
                      />
                      <ResponsiveTableRow
                        errorCellOne={errors.contact_no}
                        errorCellTwo={errors.emergency_no}
                        memberFieldCellOne={trainer?.contact_no}
                        memberFieldCellTwo={trainer?.emergency_no}
                        memberFieldCellOneType="text"
                        memberFieldCellTwoType="text"
                        isSmallScreen={isSmallScreen}
                        fieldOneEditable={editableFields}
                        fieldTwoEditable={editableFields}
                        cellOneName="Contact Number"
                        cellTwoName="Emergency Number"
                        fieldOneName="contact_no"
                        fieldTwoName="emergency_no"
                        handleInputChange={handleInputChange}
                      />
                      <ResponsiveTableRow
                        errorCellOne={errors.email_id}
                        errorCellTwo={errors.timing}
                        memberFieldCellOne={trainer?.email_id}
                        memberFieldCellTwo={trainer?.timing}
                        memberFieldCellTwoValue={trainer?.timing}
                        memberFieldCellTwoOptions={times}
                        memberFieldCellOneType="text"
                        memberFieldCellTwoType="dropdown"
                        isSmallScreen={isSmallScreen}
                        fieldOneEditable={editableFields}
                        fieldTwoEditable={editableFields}
                        cellOneName="Email"
                        cellTwoName="Select Time"
                        fieldOneName="email_id"
                        fieldTwoName="timing"
                        handleInputChange={handleInputChange}
                      />
                      <ResponsiveTableRow
                        errorCellOne={errors.address}
                        errorCellTwo={errors.blood_group}
                        memberFieldCellOne={trainer?.address}
                        memberFieldCellTwo={trainer?.blood_group}
                        memberFieldCellTwoValue={trainer?.blood_group}
                        memberFieldCellTwoOptions={bloodGroups}
                        memberFieldCellOneType="text"
                        memberFieldCellTwoType="dropdown"
                        isSmallScreen={isSmallScreen}
                        fieldOneEditable={editableFields}
                        fieldTwoEditable={editableFields}
                        cellOneName="Address"
                        cellTwoName="Blood Group"
                        handleInputChange={handleInputChange}
                        fieldOneName="address"
                        fieldTwoName="blood_group"
                      />
                      <ResponsiveTableRow
                        memberFieldCellOne={trainer?.date_of_birth}
                        memberFieldCellOneType="date"
                        isSmallScreen={isSmallScreen}
                        fieldOneEditable={editableFields}
                        fieldTwoEditable={editableFields}
                        cellOneName="Date Of Birth"
                        handleInputChange={handleInputChange}
                        fieldOneName="date_of_birth"
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
                {isSmallScreen ? (
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    sx={{ mt: 3 }}
                  >
                    <Button
                      disabled={!editcud.save}
                      variant="contained"
                      size="small"
                      startIcon={<SaveIcon />}
                      onClick={handleSaveClick}
                    >
                      Save Details
                    </Button>
                  </Grid>
                ) : (
                  ""
                )}
              </TabPanel>
            </TabContext>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};

export default ViewTrainerDetails;
