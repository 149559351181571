import React, { useRef } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import "./testimonials.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styled } from "@mui/system";

const CustomDivider = styled(Divider)(({ theme }) => ({
  "&::before, &::after": {
    borderTop: `4px solid rgba(0,0,0,0.1)`,
  },
}));

const items = [
  {
    image: "profiles/img1.jpg",
    name: "Pankaj Lokhande",
    title: "Trainee",
    description:
      "“Lorem ipsum dolor sit amet, adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquat enim ad minim veniam.”",
  },
  {
    image: "profiles/img2.jpg",
    name: "Vivek Kumbhar",
    title: "Instructor",
    description:
      "“Lorem ipsum dolor sit amet, adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquat enim ad minim veniam.”",
  },
  {
    image: "profiles/img3.jpg",
    name: "Ravindra Kumbhar",
    title: "Trainer",
    description:
      "“Lorem ipsum dolor sit amet, adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliquat enim ad minim veniam.”",
  },
];

const NextPrevButtons = ({ next, previous }) => {
  return (
    <div className="testimonialButtons">
      <IconButton size="large" onClick={previous} className="_left">
        <ArrowBackIosIcon />
      </IconButton>
      <IconButton size="large" onClick={next} className="_right">
        <ArrowForwardIosIcon />
      </IconButton>
    </div>
  );
};

const Item = ({ item }) => {
  return (
    <>
      <CustomDivider>
        <Avatar
          sx={{
            width: 100,
            height: 100,
          }}
          src={item.image}
          alt={item.title}
        />
      </CustomDivider>
      <Grid></Grid>
      <Card
        sx={{
          display: "inline-flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "transparent",
          width: 1,
          boxShadow: "none",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            padding: "0px",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              padding: "0px 0px 30px 0px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              justifyContent={"center"}
              sx={{ py: 5 }}
            >
              {item.title}
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{ textAlign: "center" }}
            >
              {item.description}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
const Testimonials = () => {
  const carouselRef = useRef(null);
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 1,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 600,
      },
      items: 1,
      partialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: {
        max: 600,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 0,
    },
  };
  return (
    <div
      style={{
        position: "relative",
        backgroundImage: `url('/paperBg.jpg')`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        display: "flex",
        minHeight: "320px",
        flexDirection: "column",
        justifyContent: "space-around",
        padding: "50px 20px",
      }}
    >
      <img
        alt=""
        src="quoteTop.svg"
        style={{ position: "absolute", left: "6%", top: "150px", opacity: 0.2 }}
      />
      <img
        alt=""
        src="quoteBot.svg"
        style={{
          position: "absolute",
          right: "6%",
          bottom: "75px",
          opacity: 0.2,
        }}
      />
      <Grid container spacing={2}>
        <Grid item md={1}></Grid>
        <Grid item xs={12} md={10}>
          <Box sx={{ width: "100%", position: "relative" }}>
            <NextPrevButtons
              next={() => carouselRef.current.next()}
              previous={() => carouselRef.current.previous()}
            />
            <Typography
              variant="h5"
              sx={{
                width: "100%",
                position: "relative",
                height: "50px",
                lineHeight: 2,
              }}
            >
              Testimonial
            </Typography>
          </Box>
          <Carousel
            ref={(el) => (carouselRef.current = el)}
            additionalTransfrom={0}
            autoPlaySpeed={3000}
            centerMode={false}
            containerClass="container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            showDots={true}
            responsive={responsive}
            rewind={true}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            sliderClass="testSliderClass"
            slidesToSlide
            swipeable
            arrows={false}
          >
            {items.map((item, index) => (
              <Item key={index} item={item} sx={{ width: 1 }} />
            ))}
          </Carousel>
        </Grid>
        <Grid item md={1}></Grid>
      </Grid>
    </div>
  );
};

export default Testimonials;
