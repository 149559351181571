import React from "react";
import MainCard from "./MainCard";
import { Grid, Stack, Typography } from "@mui/material";

const AnalyticCard = ({ title, count, extra }) => {
  return (
    <MainCard contentSX={{ p: 2.25 }}>
      <Stack spacing={0.5}>
        <Typography variant="h6" color="textSecondary">
          {title}
        </Typography>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h4" color="inherit">
              {count}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
    </MainCard>
  );
};

export default AnalyticCard;
