import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import "./CapturePhotoNonMobileDialog.css";
import CameraIcon from "@mui/icons-material/CameraAlt";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CapturePhotoNonMobileDialog(props) {
  const { open, setOpen, setAvatar } = props;
  const handleClose = () => {
    stopWebcam();
    setOpen(false);
  };

  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [mediaStream, setMediaStream] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);

  useEffect(() => {
    startWebcam();
    return () => {
      stopWebcam();
    };
  }, []);

  const startWebcam = async () => {
    try {
      setCapturedImage(null);
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "user",
        },
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      setMediaStream(stream);
    } catch (error) {
      console.error("Error accessing webcam", error);
    }
  };

  const stopWebcam = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
      setMediaStream(null);
    }
  };

  const captureImage = () => {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      if (context && video.videoWidth && video.videoHeight) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        const imageDataUrl = canvas.toDataURL("image/jpeg");

        setCapturedImage(imageDataUrl);

        stopWebcam();
      }
    }
  };

  const handleSaveClick = () => {
    setAvatar(capturedImage);
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Capture
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box
            sx={{ width: "100%", display: "inline-block", objectFit: "cover" }}
          >
            {capturedImage ? (
              <div class="output">
                <img
                  src={capturedImage}
                  id="photo"
                  alt="The screen capture will appear in this box."
                />

                <Button
                  variant="contained"
                  size="small"
                  onClick={startWebcam}
                  sx={{ marginY: 1 }}
                >
                  Retake photo
                </Button>
              </div>
            ) : (
              <div class="output">
                <video id="video" ref={videoRef} autoPlay muted>
                  Video stream not available.
                </video>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<CameraIcon />}
                  onClick={captureImage}
                  sx={{ borderRadius: 8, paddingLeft: 2.5, marginY: 1 }}
                ></Button>
              </div>
            )}
          </Box>
          {captureImage && (
            <Box sx={{ width: "100%", display: "inline-block" }}>
              <canvas id="canvas" ref={canvasRef}>
                Video stream not available.
              </canvas>
            </Box>
          )}
          <canvas id="canvas"></canvas>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSaveClick} disabled={!capturedImage}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
