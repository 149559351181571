import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  focusedTextField: "",
};

export const focusInputSlice = createSlice({
  name: "focus",
  initialState,
  reducers: {
    setFocusOnInputField: (state, action) => {
      state.focusedTextField = action.payload;
    },
    resetFocus: (state) => {
      state.focusedTextField = null;
    },
  },
});

export const { setFocusOnInputField, resetFocus } = focusInputSlice.actions;

export default focusInputSlice.reducer;
