import {
  TableRow,
  TableCell,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Switch,
  FormHelperText,
} from "@mui/material";
import styled from "@emotion/styled";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useState } from "react";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "unset",
  },
  "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "0px",
  },
}));

export default function ResponsiveTableRow(props) {
  const {
    errorCellOne,
    errorCellTwo,
    memberFieldCellOne,
    memberFieldCellTwo,
    memberFieldCellOneType,
    memberFieldCellTwoType,
    isRequired,
    memberFieldCellOneValue,
    memberFieldCellTwoValue,
    memberFieldCellOneOptions,
    memberFieldCellTwoOptions,
    isSmallScreen,
    fieldOneEditable,
    fieldTwoEditable,
    cellOneName,
    cellTwoName,
    handleInputChange,
    fieldOneName,
    fieldTwoName,
  } = props;

  const handleDatePickerChange = (date, field) => {
    let selectedDate = dayjs(date).format();

    if (
      memberFieldCellOneType === "date" ||
      memberFieldCellTwoType === "date"
    ) {
      handleInputChange(field, selectedDate, "text");
    }
  };

  const commonStyleForInput = {
    label: {
      shrink: true,
      style: { fontWeight: "bold", textTransform: "uppercase", color: "" },
    },
    input: {
      style: { color: "#242105" },
    },
  };

  const [isActive, setIsActive] = useState(true);

  const statusLabel = isActive ? "Active" : "Inactive";

  const handletoggle = () => {
    setIsActive(!isActive);
  };
  return (
    <>
      {isSmallScreen ? (
        <>
          <TableRow>
            {" "}
            {/* 1st row cell one  */}
            {memberFieldCellOneType === "text" ? ( //cell one for text
              <TableCell component="th" scope="row" sx={{ width: "50%" }}>
                <CustomTextField
                  fullWidth
                  required={isRequired}
                  error={!!errorCellOne}
                  helperText={errorCellOne}
                  disabled={!fieldOneEditable}
                  label={cellOneName}
                  value={memberFieldCellOne}
                  InputLabelProps={{
                    ...commonStyleForInput.label,
                  }}
                  inputProps={
                    fieldOneName === "emergency_no" ||
                    fieldOneName === "contact_no" ||
                    fieldOneName === "paidNow" ||
                    fieldOneName === "totalAmount"
                      ? {
                          ...commonStyleForInput.input,
                          ...commonStyleForInput.disabled,
                          type: "number",
                          inputProps: {
                            maxLength: 10, // Enforce 10-digit limit
                          },
                        }
                      : {
                          ...commonStyleForInput.input,
                          ...commonStyleForInput.disabled,
                          type: "text",
                        }
                  }
                  onChange={(e) =>
                    handleInputChange(fieldOneName, e.target.value, "text")
                  }
                  onBlur={(e) =>
                    fieldOneName === "paidNow" &&
                    handleInputChange(fieldOneName, e.target.value, "onBlur")
                  }
                />
              </TableCell>
            ) : memberFieldCellOneType === "dropdown" ? (
              <TableCell sx={{ width: "50%" }}>
                <FormControl
                  fullWidth
                  required={isRequired}
                  error={!!errorCellTwo}
                >
                  <InputLabel id={fieldOneName}>{cellOneName}</InputLabel>
                  <Select
                    labelId={fieldOneName}
                    InputLabelProps={{
                      ...commonStyleForInput.label,
                    }}
                    label={cellOneName}
                    required={isRequired}
                    value={memberFieldCellOneValue}
                    disabled={!fieldOneEditable}
                    onChange={(e) =>
                      handleInputChange(fieldOneName, e.target.value, "text")
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "rgb(36, 33, 5)",
                      },
                      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        border: "0px",
                      },
                    }}
                  >
                    {fieldOneEditable
                      ? [
                          memberFieldCellOneOptions?.map((field, index) => [
                            <MenuItem
                              key={index}
                              value={field.id ? field.id : field}
                              name={field.name ? field.name : field}
                            >
                              {field.name ? field.name : field}
                            </MenuItem>,
                          ]),
                        ]
                      : [
                          <MenuItem
                            key={memberFieldCellOneValue}
                            value={memberFieldCellOneValue}
                          >
                            {memberFieldCellOne}
                          </MenuItem>,
                        ]}
                  </Select>
                  {errorCellOne ? (
                    <FormHelperText>Please select an Option</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </TableCell>
            ) : memberFieldCellOneType === "date" ? (
              <TableCell sx={{ width: "50%" }} component="th" scope="row">
                <FormControl error={errorCellOne !== ""} sx={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      fullWidth
                      required={isRequired}
                      label={cellOneName}
                      value={
                        memberFieldCellOne ? dayjs(memberFieldCellOne) : ""
                      }
                      disableFuture={true}
                      disabled={!fieldOneEditable}
                      format="DD/MM/YYYY"
                      onChange={(date) =>
                        handleDatePickerChange(date, fieldOneName)
                      }
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "rgb(36, 33, 5)",
                        },
                        "& button.Mui-disabled": {
                          display: "none",
                        },
                        "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
                          border: "0px",
                        },
                        width: "100%",
                      }}
                      textField={(props) => (
                        <TextField
                          {...props}
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {errorCellOne ? (
                    <FormHelperText>Please select an Date</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </TableCell>
            ) : memberFieldCellTwoType === "status" ? (
              <TableRow>
                <TableCell sx={{ width: "50%" }} component="th" scope="row">
                  <Typography>Status </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={memberFieldCellTwo ? true : false}
                        onChange={(e) => {
                          handletoggle();
                          handleInputChange(
                            "status",
                            e.target.checked,
                            "switch"
                          );
                        }}
                        color="primary"
                        inputProps={{ "aria-label": "toggle switch" }}
                        disabled={!fieldTwoEditable}
                      />
                    }
                    label={
                      memberFieldCellTwo ? memberFieldCellTwo : statusLabel
                    }
                  />
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}{" "}
          </TableRow>

          <TableRow>
            {memberFieldCellTwoType === "dropdown" ? (
              <TableCell sx={{ width: "50%" }}>
                <FormControl
                  fullWidth
                  required={isRequired}
                  error={!!errorCellTwo}
                >
                  <InputLabel id={fieldTwoName}>{cellTwoName}</InputLabel>
                  <Select
                    labelId={fieldTwoName}
                    label={cellTwoName}
                    required={isRequired}
                    value={memberFieldCellTwoValue}
                    disabled={!fieldTwoEditable}
                    onChange={(e) =>
                      handleInputChange(fieldTwoName, e.target.value, "text")
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "rgb(36, 33, 5)",
                      },
                      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        border: "0px",
                      },
                    }}
                  >
                    {fieldTwoEditable
                      ? [
                          memberFieldCellTwoOptions?.map((field, index) => [
                            <MenuItem
                              key={index}
                              value={field.id ? field.id : field}
                              name={field.name ? field.name : field}
                            >
                              {field.name ? field.name : field}
                            </MenuItem>,
                          ]),
                        ]
                      : [
                          <MenuItem
                            key={memberFieldCellTwoValue}
                            value={memberFieldCellTwoValue}
                            name={memberFieldCellTwo}
                          >
                            {memberFieldCellTwo}
                          </MenuItem>,
                        ]}
                  </Select>
                  {errorCellTwo ? (
                    <FormHelperText>Please select an Option</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </TableCell>
            ) : memberFieldCellTwoType === "text" ? ( //cellTwo text
              <TableCell sx={{ width: "50%" }} component="th" scope="row">
                <CustomTextField
                  fullWidth
                  required={isRequired}
                  error={!!errorCellTwo}
                  helperText={errorCellTwo}
                  disabled={!fieldTwoEditable}
                  label={cellTwoName}
                  value={memberFieldCellTwo}
                  InputLabelProps={{
                    ...commonStyleForInput.label,
                  }}
                  inputProps={
                    fieldTwoName === "emergency_no" ||
                    fieldTwoName === "contact_no" ||
                    fieldTwoName === "paidNow"
                      ? {
                          ...commonStyleForInput.input,
                          ...commonStyleForInput.disabled,
                          type: "number",
                          inputProps: {
                            maxLength: 10, // Limit to 10 digits
                          },
                        }
                      : {
                          ...commonStyleForInput.input,
                          ...commonStyleForInput.disabled,
                          type: "text",
                        }
                  }
                  onChange={(e) => {
                    handleInputChange(fieldTwoName, e.target.value, "text");
                  }}
                  onBlur={(e) =>
                    fieldTwoName === "paidNow" &&
                    handleInputChange(fieldTwoName, e.target.value, "onBlur")
                  }
                />
              </TableCell>
            ) : memberFieldCellTwoType === "date" ? (
              <TableCell sx={{ width: "50%" }} component="th" scope="row">
                <FormControl error={errorCellTwo !== ""}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      fullWidth
                      required={isRequired}
                      label={cellTwoName}
                      value={
                        memberFieldCellTwo ? dayjs(memberFieldCellTwo) : ""
                      }
                      disableFuture={true}
                      disabled={!fieldTwoEditable}
                      onChange={(date) =>
                        handleDatePickerChange(date, fieldTwoName)
                      }
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "rgb(36, 33, 5)",
                        },
                        "& button.Mui-disabled": {
                          display: "none",
                        },
                        "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
                          border: "0px",
                        },
                      }}
                      textField={(props) => (
                        <TextField
                          {...props}
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {errorCellOne ? (
                    <FormHelperText>Please select an Date</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </TableCell>
            ) : memberFieldCellTwoType === "status" ? (
              <TableRow>
                <TableCell sx={{ width: "50%" }} component="th" scope="row">
                  <Typography>Status </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={memberFieldCellTwo ? true : false}
                        onChange={(e) => {
                          handletoggle();
                          handleInputChange(
                            "status",
                            e.target.checked,
                            "switch"
                          );
                        }}
                        color="primary"
                        inputProps={{ "aria-label": "toggle switch" }}
                        disabled={!fieldTwoEditable}
                      />
                    }
                    label={
                      memberFieldCellTwo ? memberFieldCellTwo : statusLabel
                    }
                  />
                </TableCell>
              </TableRow>
            ) : memberFieldCellTwoType === "date" ? (
              <TableCell sx={{ width: "50%" }} component="th" scope="row">
                <FormControl error={errorCellTwo !== ""} sx={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      fullWidth
                      required={isRequired}
                      label={cellTwoName}
                      value={
                        memberFieldCellTwo ? dayjs(memberFieldCellTwo) : ""
                      }
                      disableFuture={true}
                      disabled={!fieldTwoEditable}
                      format="DD/MM/YYYY"
                      onChange={(date) =>
                        handleDatePickerChange(date, fieldTwoName)
                      }
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "rgb(36, 33, 5)",
                        },
                        "& button.Mui-disabled": {
                          display: "none",
                        },
                        "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
                          border: "0px",
                        },
                        width: "100%",
                      }}
                      textField={(props) => (
                        <TextField
                          {...props}
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {errorCellTwo ? (
                    <FormHelperText>Please select an Date</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </TableCell>
            ) : memberFieldCellTwoType === "text" ? ( //cellTwo text
              <TableCell sx={{ width: "50%" }} component="th" scope="row">
                <CustomTextField
                  fullWidth
                  required={isRequired}
                  error={!!errorCellTwo}
                  helperText={errorCellTwo}
                  disabled={!fieldTwoEditable}
                  label={cellTwoName}
                  value={memberFieldCellTwo}
                  InputLabelProps={{
                    ...commonStyleForInput.label,
                  }}
                  inputProps={
                    fieldTwoName === "emergency_no"
                      ? {
                          ...commonStyleForInput.input,
                          ...commonStyleForInput.disabled,
                          type: "number",
                          inputProps: {
                            maxLength: 10,
                          },
                        }
                      : {
                          ...commonStyleForInput.input,
                          ...commonStyleForInput.disabled,
                          type: "text",
                        }
                  }
                  onChange={(e) => {
                    handleInputChange(fieldTwoName, e.target.value, "text");
                  }}
                />
              </TableCell>
            ) : memberFieldCellTwoType === "date" ? (
              <TableCell sx={{ width: "50%" }} component="th" scope="row">
                <FormControl error={errorCellTwo !== ""}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      fullWidth
                      required={isRequired}
                      label={cellTwoName}
                      value={
                        memberFieldCellTwo ? dayjs(memberFieldCellTwo) : ""
                      }
                      disableFuture={true}
                      disabled={!fieldTwoEditable}
                      onChange={(date) =>
                        handleDatePickerChange(date, fieldTwoName)
                      }
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "rgb(36, 33, 5)",
                        },
                        "& button.Mui-disabled": {
                          display: "none",
                        },
                        "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
                          border: "0px",
                        },
                      }}
                      textField={(props) => (
                        <TextField
                          {...props}
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {errorCellOne ? (
                    <FormHelperText>Please select an Date</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </TableCell>
            ) : memberFieldCellTwoType === "status" ? (
              <TableRow>
                <TableCell sx={{ width: "50%" }} component="th" scope="row">
                  <Typography>Status </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={memberFieldCellTwo ? true : false}
                        onChange={(e) => {
                          handletoggle();
                          handleInputChange(
                            "status",
                            e.target.checked,
                            "switch"
                          );
                        }}
                        color="primary"
                        inputProps={{ "aria-label": "toggle switch" }}
                        disabled={!fieldTwoEditable}
                      />
                    }
                    label={
                      memberFieldCellTwo ? memberFieldCellTwo : statusLabel
                    }
                  />
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}{" "}
          </TableRow>
          {/* row two start */}
          {/* <TableRow>
            {memberFieldCellTwoType === "dropdown" ? (
              <TableCell sx={{ width: "50%" }}>
                <FormControl
                  fullWidth
                  required={isRequired}
                  error={!!errorCellTwo}
                >
                  <InputLabel id={fieldTwoName}>{cellTwoName}</InputLabel>
                  <Select
                    labelId={fieldTwoName}
                    label={cellTwoName}
                    required={isRequired}
                    value={memberFieldCellTwoValue}
                    disabled={!fieldTwoEditable}
                    onChange={(e) =>
                      handleInputChange(fieldTwoName, e.target.value, "text")
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "rgb(36, 33, 5)",
                      },
                      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        border: "0px",
                      },
                    }}
                  >
                    {fieldTwoEditable
                      ? [
                          memberFieldCellTwoOptions?.map((field, index) => [
                            <MenuItem
                              key={index}
                              value={field.id ? field.id : field}
                              name={field.name ? field.name : field}
                            >
                              {field.name ? field.name : field}
                            </MenuItem>,
                          ]),
                        ]
                      : [
                          <MenuItem
                            key={memberFieldCellTwoValue}
                            value={memberFieldCellTwoValue}
                            name={memberFieldCellTwo}
                          >
                            {memberFieldCellTwo}
                          </MenuItem>,
                        ]}
                  </Select>
                  {errorCellTwo ? (
                    <FormHelperText>Please select an Option</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </TableCell>
            ) : memberFieldCellTwoType === "text" ? ( //cellTwo text
              <TableCell sx={{ width: "50%" }} component="th" scope="row">
                <CustomTextField
                  fullWidth
                  required={isRequired}
                  error={!!errorCellTwo}
                  helperText={errorCellTwo}
                  disabled={!fieldTwoEditable}
                  label={cellTwoName}
                  value={memberFieldCellTwo}
                  InputLabelProps={{
                    ...commonStyleForInput.label,
                  }}
                  inputProps={
                    fieldTwoName === "emergency_no"
                      ? {
                          ...commonStyleForInput.input,
                          ...commonStyleForInput.disabled,
                          type: "number",
                          inputProps: {
                            maxLength: 10, // Limit to 10 digits
                          },
                        }
                      : {
                          ...commonStyleForInput.input,
                          ...commonStyleForInput.disabled,
                          type: "text",
                        }
                  }
                  onChange={(e) => {
                    handleInputChange(fieldTwoName, e.target.value, "text");
                  }}
                />
              </TableCell>
            ) : memberFieldCellTwoType === "date" ? (
              <TableCell sx={{ width: "50%" }} component="th" scope="row">
                <FormControl error={errorCellTwo !== ""}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      fullWidth
                      required={isRequired}
                      label={cellTwoName}
                      value={
                        memberFieldCellTwo ? dayjs(memberFieldCellTwo) : ""
                      }
                      disableFuture={true}
                      disabled={!fieldTwoEditable}
                      onChange={(date) =>
                        handleDatePickerChange(date, fieldTwoName)
                      }
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "rgb(36, 33, 5)",
                        },
                        "& button.Mui-disabled": {
                          display: "none",
                        },
                        "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
                          border: "0px",
                        },
                      }}
                      textField={(props) => (
                        <TextField
                          {...props}
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {errorCellOne ? (
                    <FormHelperText>Please select an Date</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </TableCell>
            ) : memberFieldCellTwoType === "status" ? (
              <TableRow>
                <TableCell sx={{ width: "50%" }} component="th" scope="row">
                  <Typography>Status </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={memberFieldCellTwo ? true : false}
                        onChange={(e) => {
                          handletoggle();
                          handleInputChange(
                            "status",
                            e.target.checked,
                            "switch"
                          );
                        }}
                        color="primary"
                        inputProps={{ "aria-label": "toggle switch" }}
                        disabled={!fieldTwoEditable}
                      />
                    }
                    label={
                      memberFieldCellTwo ? memberFieldCellTwo : statusLabel
                    }
                  />
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}{" "}
          </TableRow> */}
          {/* row two start */}
          {/* <TableRow>
            {memberFieldCellTwoType === "dropdown" ? (
              <TableCell sx={{ width: "50%" }}>
                <FormControl
                  fullWidth
                  required={isRequired}
                  error={!!errorCellTwo}
                >
                  <InputLabel id={fieldTwoName}>{cellTwoName}</InputLabel>
                  <Select
                    labelId={fieldTwoName}
                    label={cellTwoName}
                    required={isRequired}
                    value={memberFieldCellTwoValue}
                    disabled={!fieldTwoEditable}
                    onChange={(e) =>
                      handleInputChange(fieldTwoName, e.target.value, "text")
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "rgb(36, 33, 5)",
                      },
                      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        border: "0px",
                      },
                    }}
                  >
                    {fieldTwoEditable
                      ? [
                          memberFieldCellTwoOptions?.map((field, index) => [
                            <MenuItem
                              key={index}
                              value={field.id ? field.id : field}
                              name={field.name ? field.name : field}
                            >
                              {field.name ? field.name : field}
                            </MenuItem>,
                          ]),
                        ]
                      : [
                          <MenuItem
                            key={memberFieldCellTwoValue}
                            value={memberFieldCellTwoValue}
                            name={memberFieldCellTwo}
                          >
                            {memberFieldCellTwo}
                          </MenuItem>,
                        ]}
                  </Select>
                  {errorCellTwo ? (
                    <FormHelperText>Please select an Option</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </TableCell>
            ) : memberFieldCellTwoType === "text" ? (
              <TableCell sx={{ width: "50%" }} component="th" scope="row">
                <CustomTextField
                  fullWidth
                  required={isRequired}
                  error={!!errorCellTwo}
                  helperText={errorCellTwo}
                  disabled={!fieldTwoEditable}
                  label={cellTwoName}
                  value={memberFieldCellTwo}
                  InputLabelProps={{
                    ...commonStyleForInput.label,
                  }}
                  inputProps={
                    fieldTwoName === "emergency_no"
                      ? {
                          ...commonStyleForInput.input,
                          ...commonStyleForInput.disabled,
                          type: "number",
                          inputProps: {
                            maxLength: 10,
                          },
                        }
                      : {
                          ...commonStyleForInput.input,
                          ...commonStyleForInput.disabled,
                          type: "text",
                        }
                  }
                  onChange={(e) => {
                    handleInputChange(fieldTwoName, e.target.value, "text");
                  }}
                />
              </TableCell>
            ) : memberFieldCellTwoType === "date" ? (
              <TableCell sx={{ width: "50%" }} component="th" scope="row">
                <FormControl error={errorCellTwo !== ""}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      fullWidth
                      required={isRequired}
                      label={cellTwoName}
                      value={
                        memberFieldCellTwo ? dayjs(memberFieldCellTwo) : ""
                      }
                      disableFuture={true}
                      disabled={!fieldTwoEditable}
                      onChange={(date) =>
                        handleDatePickerChange(date, fieldTwoName)
                      }
                      sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "rgb(36, 33, 5)",
                        },
                        "& button.Mui-disabled": {
                          display: "none",
                        },
                        "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
                          border: "0px",
                        },
                      }}
                      textField={(props) => (
                        <TextField
                          {...props}
                          variant="standard"
                          margin="normal"
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {errorCellOne ? (
                    <FormHelperText>Please select an Date</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </TableCell>
            ) : memberFieldCellTwoType === "status" ? (
              <TableRow>
                <TableCell sx={{ width: "50%" }} component="th" scope="row">
                  <Typography>Status </Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={memberFieldCellTwo ? true : false}
                        onChange={(e) => {
                          handletoggle();
                          handleInputChange(
                            "status",
                            e.target.checked,
                            "switch"
                          );
                        }}
                        color="primary"
                        inputProps={{ "aria-label": "toggle switch" }}
                        disabled={!fieldTwoEditable}
                      />
                    }
                    label={
                      memberFieldCellTwo ? memberFieldCellTwo : statusLabel
                    }
                  />
                </TableCell>
              </TableRow>
            ) : (
              ""
            )}
          </TableRow>{" "} */}
        </>
      ) : (
        <TableRow>
          {memberFieldCellOneType === "text" ? (
            <TableCell sx={{ width: "50%" }} component="th" scope="row">
              <CustomTextField
                fullWidth
                required={isRequired}
                error={!!errorCellOne}
                helperText={errorCellOne}
                disabled={!fieldOneEditable}
                label={cellOneName}
                value={memberFieldCellOne}
                InputLabelProps={{
                  ...commonStyleForInput.label,
                }}
                InputProps={
                  fieldOneName === "emergency_no" ||
                  fieldOneName === "contact_no" ||
                  fieldOneName === "paidNow" ||
                  fieldOneName === "totalAmount"
                    ? {
                        ...commonStyleForInput.input,
                        ...commonStyleForInput.disabled,
                        type: "number",
                        inputProps: {
                          maxLength: 10,
                        },
                      }
                    : {
                        ...commonStyleForInput.input,
                        ...commonStyleForInput.disabled,
                        type: "text",
                      }
                }
                onChange={(e) =>
                  handleInputChange(fieldOneName, e.target.value, "text")
                }
                onBlur={(e) =>
                  fieldOneName === "paidNow" &&
                  handleInputChange(fieldOneName, e.target.value, "onBlur")
                }
              />
            </TableCell>
          ) : memberFieldCellOneType === "dropdown" ? (
            <TableCell sx={{ width: "50%" }}>
              <FormControl
                fullWidth
                required={isRequired}
                error={!!errorCellOne}
              >
                <InputLabel id={fieldOneName}>{cellOneName}</InputLabel>
                <Select
                  labelId={fieldOneName}
                  label={cellOneName}
                  required={isRequired}
                  value={memberFieldCellOneValue}
                  disabled={!fieldOneEditable}
                  onChange={(e) =>
                    handleInputChange(fieldOneName, e.target.value, "text")
                  }
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "rgb(36, 33, 5)",
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                      border: "0px",
                    },
                  }}
                >
                  {fieldOneEditable
                    ? [
                        memberFieldCellOneOptions?.map((field, index) => [
                          <MenuItem
                            key={index}
                            value={field.id ? field.id : field}
                            name={field.name ? field.name : field}
                          >
                            {field.name ? field.name : field}
                          </MenuItem>,
                        ]),
                      ]
                    : [
                        <MenuItem
                          key={memberFieldCellOneValue}
                          value={memberFieldCellOneValue}
                        >
                          {memberFieldCellOne}
                        </MenuItem>,
                      ]}
                </Select>
                {errorCellOne ? (
                  <FormHelperText>Please select an Option</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </TableCell>
          ) : memberFieldCellOneType === "date" ? (
            <TableCell sx={{ width: "50%" }} component="th" scope="row">
              <FormControl error={errorCellOne !== ""} sx={{ width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    required={isRequired}
                    label={cellOneName}
                    value={dayjs(memberFieldCellOne)}
                    disableFuture={true}
                    disabled={!fieldOneEditable}
                    format="DD/MM/YYYY"
                    onChange={(date) =>
                      handleDatePickerChange(date, fieldOneName)
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "rgb(36, 33, 5)",
                      },
                      "& button.Mui-disabled": {
                        display: "none",
                      },
                      "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        border: "0px",
                      },
                      width: "100%",
                    }}
                    textField={(props) => (
                      <TextField
                        {...props}
                        variant="standard"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                  {errorCellOne ? (
                    <FormHelperText>Please select an Date</FormHelperText>
                  ) : (
                    ""
                  )}
                </LocalizationProvider>
              </FormControl>
            </TableCell>
          ) : (
            ""
          )}

          {memberFieldCellTwoType === "dropdown" ? (
            <TableCell sx={{ width: "50%" }}>
              <FormControl
                fullWidth
                required={isRequired}
                error={!!errorCellTwo}
              >
                <InputLabel id={fieldTwoName}>{cellTwoName}</InputLabel>
                <Select
                  labelId={fieldTwoName}
                  label={cellTwoName}
                  required={isRequired}
                  error={!!errorCellTwo}
                  helperText={errorCellTwo}
                  value={memberFieldCellTwoValue}
                  disabled={!fieldTwoEditable}
                  onChange={(e) =>
                    handleInputChange(fieldTwoName, e.target.value, "text")
                  }
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: "rgb(36, 33, 5)",
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                      border: "0px",
                    },
                  }}
                >
                  {fieldTwoEditable
                    ? [
                        memberFieldCellTwoOptions?.map((field, index) => [
                          <MenuItem
                            key={index}
                            value={field.id ? field.id : field}
                          >
                            {field.name ? field.name : field}
                          </MenuItem>,
                        ]),
                      ]
                    : [
                        <MenuItem
                          key={memberFieldCellTwoValue}
                          value={memberFieldCellTwoValue}
                        >
                          {memberFieldCellTwo}
                        </MenuItem>,
                      ]}
                </Select>
                {errorCellTwo ? (
                  <FormHelperText>Please select an Option</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </TableCell>
          ) : memberFieldCellTwoType === "text" ? (
            <TableCell sx={{ width: "50%" }} component="th" scope="row">
              <CustomTextField
                fullWidth
                required={isRequired}
                error={!!errorCellTwo}
                helperText={errorCellTwo}
                disabled={!fieldTwoEditable}
                label={cellTwoName}
                value={memberFieldCellTwo}
                InputLabelProps={{
                  ...commonStyleForInput.label,
                }}
                InputProps={
                  fieldTwoName === "emergency_no" ||
                  fieldTwoName === "contact_no" ||
                  fieldTwoName === "paidNow"
                    ? {
                        ...commonStyleForInput.input,
                        ...commonStyleForInput.disabled,
                        type: "number",
                        inputProps: {
                          maxLength: 10,
                        },
                      }
                    : {
                        ...commonStyleForInput.input,
                        ...commonStyleForInput.disabled,
                        type: "text",
                      }
                }
                onChange={(e) =>
                  handleInputChange(fieldTwoName, e.target.value, "text")
                }
                onBlur={(e) =>
                  fieldTwoName === "paidNow" &&
                  handleInputChange(fieldTwoName, e.target.value, "onBlur")
                }
              />
            </TableCell>
          ) : memberFieldCellTwoType === "date" ? (
            <TableCell sx={{ width: "50%" }} component="th" scope="row">
              <FormControl error={errorCellTwo !== ""} sx={{ width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    fullWidth
                    required={isRequired}
                    label={cellTwoName}
                    value={dayjs(memberFieldCellTwo)}
                    disableFuture={true}
                    disabled={!fieldTwoEditable}
                    format="DD/MM/YYYY"
                    onChange={(date) =>
                      handleDatePickerChange(date, fieldTwoName)
                    }
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "rgb(36, 33, 5)",
                      },
                      "& button.Mui-disabled": {
                        display: "none",
                      },
                      "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        border: "0px",
                      },
                      width: "100%",
                    }}
                    textField={(props) => (
                      <TextField
                        {...props}
                        variant="standard"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
                {errorCellTwo ? (
                  <FormHelperText>Please select an Date</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </TableCell>
          ) : memberFieldCellTwoType === "status" ? (
            <TableRow>
              <TableCell sx={{ width: "50%" }} component="th" scope="row">
                <Typography>Status </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={memberFieldCellTwo ? true : false}
                      onChange={(e) => {
                        handleInputChange("status", e.target.checked, "switch");
                      }}
                      color="primary"
                      inputProps={{ "aria-label": "toggle switch" }}
                      disabled={!fieldTwoEditable}
                    />
                  }
                  label={memberFieldCellTwo ? "Active" : "Inactive"}
                />
              </TableCell>
            </TableRow>
          ) : (
            ""
          )}
        </TableRow>
      )}
    </>
  );
}
