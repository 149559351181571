import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import AnalyticCard from "../cards/AnalyticCard";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import MainCard from "../cards/MainCard";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMembersData,
  selectMembersData,
  selectMembersStatus,
  setMembersData,
} from "../../../../slices/membersSlice";

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Members = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const membersData = useSelector(selectMembersData);
  const membersStatus = useSelector(selectMembersStatus);
  const activeMembers = membersData.filter((member) => member?.status).length;
  const [isActive, setIsActive] = useState(false);
  const status = membersData ? "Active" : "Inactive";

  const handletoggle = (value) => {
    setIsActive(value);
  };

  useEffect(() => {
    console.log("memberStatus: ", membersStatus);
    if (membersStatus === "idle") {
      dispatch(fetchMembersData());
    } else {
      // Update membersData if needed
      dispatch(setMembersData(membersData));
    }
  }, [dispatch, membersStatus, setMembersData]);

  useEffect(() => {
    dispatch(fetchMembersData());
  }, []);
  const handleRowClick = (params, event, details) => {
    const selectedMemberId = params.row.id;
    navigate(`/adminapp/memberDetails/${selectedMemberId}`);
  };

  const handleRows = (status) => {
    return status ? membersData : membersData.filter((member) => member.status);
  };

  if (membersStatus === "loading") {
    return <div>Loading...</div>;
  }

  const membersColumns = [
    {
      field: "id",
      headerName: "ID",
      width: isSmallScreen ? 100 : 100,
      minWidth: 100,
    },
    {
      field: "name",
      headerName: "Name",
      width: isSmallScreen ? 110 : 110,
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.row.name}
          </Typography>
        );
      },
    },
    {
      field: "contact_no",
      headerName: "Contact No",
      width: isSmallScreen ? 140 : 130,
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.row.contact_no}
          </Typography>
        );
      },
    },
    {
      field: "email_id",
      headerName: "Email ID",
      width: isSmallScreen ? 150 : 150,
      minWidth: 150,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.row.email_id}
        </Typography>
      ),
    },

    {
      field: "Membership_enddate",
      headerName: "Membership End Date",
      width: isSmallScreen ? 210 : 150,
      minWidth: 150,
      renderCell: (params) => {
        const endDate = params.row.membership_enddate;
        return endDate ? dayjs(endDate).format("DD/MM/YYYY") : "N/A";
      },
    },

    {
      field: "trainer_name",
      headerName: "Trainer",
      width: isSmallScreen ? 120 : 150,
      minWidth: 150,
      renderCell: (params) => (
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.row.trainer_name}
        </Typography>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      width: isSmallScreen ? 100 : 120,
      minWidth: 120,
      renderCell: (params) => {
        const status = params.row.status;
        return status ? (
          <Chip label="Active" color="success" />
        ) : (
          <Chip label="Inactive" color="error" />
        );
      },
    },
  ];

  const addNewMember = () => {
    navigate(`/adminapp/memberDetails/`);
  };

  return (
    <Grid
      container
      rowSpacing={4.5}
      columnSpacing={2.75}
      sx={{ background: "#fafafb" }}
    >
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: -2.25 }}
      >
        <Typography variant="h5">Members</Typography>
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Button
            variant="contained"
            startIcon={<PersonAddIcon />}
            onClick={() => addNewMember()}
          >
            New Member
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticCard title="Members" count={membersData.length} extra="5" />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticCard title="Active Members" count={activeMembers} extra="5" />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">Members Details</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={isActive}
                  onChange={(e) => {
                    handletoggle(e.target.checked);
                  }}
                  color="primary"
                  inputProps={{ "aria-label": "toggle switch" }}
                />
              }
              label={!isActive ? "Active Members" : "All Members"}
            />
          </Grid>
          <Grid item />
        </Grid>
        <MainCard sx={{ mt: 2 }} content={false}>
          <Box sx={{ height: 400, width: 1 }}>
            <DataGrid
              columns={membersColumns}
              rows={handleRows(isActive)}
              pageSize={50}
              onRowClick={handleRowClick}
              initialState={{
                sorting: {
                  sortModel: [{ field: "id", sort: "desc" }],
                },
              }}
            />
          </Box>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default Members;
